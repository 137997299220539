$roboto: "Roboto", sans-serif;

.improved-ui .custom-form-block {
  .form__content {
    background-color: white;
    box-shadow: none;

    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.improved-ui{
  &.without-background {
    .form__content {
      padding: 0;
      background: transparent;
    }
  }
  &.within-card {
    @include lg {
      .form__content{ 
        box-shadow: 0 0 64px -24px rgba(0, 0, 0, 0.2) !important;
      }
    }
  }
}


.improved-ui .custom-form-block {
  margin: 0;
}

.improved-ui .custom-form-block .freeform {
  // Content
  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  // Row
  &__row {
    gap: 32px;
  }

  &__row:first-of-type {
    padding: 0;
  }

  // Column
  &__column {
    margin: 0 !important;
  }

  // Field
  &__field {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__checkbox_group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  // Single column rows
  @include lg {
    &__column--cols-1 {
      .freeform__text,
      .freeform__email {
        width: 50%;
      }
    }
  }

  // Instructions
  &__instructions {
    font-size: 12px;
    font-weight: 400;
    font-family: $roboto;
    line-height: 16px;
    margin: 0 !important;
  }

  // Label
  &__label {
    color: inherit;
    margin: 0px !important;
    font-size: 0.875rem;
    font-family: $roboto;
    line-height: 1.5;
  }

  &__label--light {
    opacity: 0.75;
  }

  &__column .freeform-input-only-label,
  &__column label:nth-of-type(n + 2) {
    font-size: 0.875rem;
    font-family: $roboto;
    line-height: 1.5;
    color: currentColor;
  }

  &__label.required:after,
  &__label.freeform-required:after {
    color: currentColor;
  }

  &__radio_group {
    .freeform__label {
      margin-bottom: 8px !important;
    }
  }

  // Input
  &__html,
  &__html a {
    font-size: 12px;
    font-weight: 400;
    font-family: $roboto;
    line-height: 16px;
    color: $neutrals-white;
  }

  &__html a {
    text-decoration: underline;
  }

  &__field input,
  &__field select,
  &__field textarea {
    padding: 8px;
    border-radius: 2px;
    color: $neutrals-n-500;
    background: $neutrals-white;
    border: 1px solid $blue-muted-bm-400;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: transparent;
    }
    &::-webkit-input-placeholder {
      color: transparent;
    }
    &:-moz-placeholder {
      color: transparent;
    }
    &::-moz-placeholder {
      color: transparent;
    }
    &:-ms-input-placeholder {
      color: transparent;
    }
    &:not(:placeholder-shown),
    &:focus {
      &::placeholder {
        color: transparent;
      }
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &:-moz-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
    }
  }

  &__field textarea {
    height: 135px;
  }
  &__input[type="checkbox"],
  &__input[type="radio"] {
    & + .marker:before {
      background: $neutrals-white;
      border-color: #707070 !important;
      border-width: 1px !important;
    }
    &:checked + .marker::after {
      background: $blue-b-400 !important;
    }
  }
  &__input[type="checkbox"] {
    & + .marker:before {
      border-radius: 2px !important;
    }
  }

  // submit
  &__column .form__submit {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    width: 100%;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: $roboto;
    background: $blue-b-400;
    min-width: 9.25rem;
    text-transform: none;

    &:hover {
      background: $blue-b-200;
    }

    &:focus {
      background: $blue-b-500;
    }

    @include lg {
      width: auto;
    }

    &::after {
      content: "\f061";
      font-size: 18px;
      font-family: "Font Awesome 5 Pro";
      color: rgb(255, 255, 255);
      display: inline-block;
      transform: rotate(0deg);
      margin-left: 4px;
    }

    &:last-of-type {
      margin: 0 !important;
    }
  }

  &__row {
    .freeform__column {
      p {
        margin: 0;
      }
    }

    &:last-of-type {
      .freeform__column {
        &:last-child {
          width: auto;
          flex: unset;
        }
      }
    }
  }
}


.freeform__instructions {
  display: inline-block !important;
}

.freeform__submit {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.form__submit {
  &--prev {
    display: inline-flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    background: $neutrals-white !important;
    border: 1px solid $blue-b-400 !important;
    color: $blue-b-400 !important;
    padding: 10px 16px !important;
    transition: background-color ease 0.3s;
    &:hover, &:focus {
      background: var(--blue-muted-bm-100)  !important;
    }
  }
  &--icon {
    width: 18px;
  }
  &--text {
    margin: 0 !important;
  }
  &--prev:after {
    content: none !important;
  }
  &:after {
    transform: rotate(0deg) !important;
    margin-left: 8px !important;
  }
}

button[type="submit"]{
  max-width: 100%;

  &:disabled,
  &:disabled::before {
    background: $blue-muted-bm-400;
    cursor: not-allowed;
  }
}


[type='phone']{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}