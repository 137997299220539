.training-apply-form {
  .freeform__column--training_name {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  &--no-header {
    margin-top: 65px; // height of navbar
  }

  &__title {
    margin-bottom: 4px !important;
    font-family: $font-secondary;

    span:first-child {
      font-family: $font-primary;
      display: block;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: -0.4px;
      padding-bottom: 44px;
    }
    span{
      font-size: 18px;
      line-height: 24px;
      white-space: pre-wrap;
    }
  }

  td {
    padding: 0 !important;
  }

  &__select-label {
    position: relative;

    display: block;

    width: 100%;
    max-width: 474px;

    font-family: $font-secondary;

    span {
      display: block;
    }
  }

  &__select-label + &__summary {
    margin-top: 32px;
  }

  &__select {
    background-color: $background-light;
  }

  &__select-icon {
    position: absolute;
    bottom: 28px;
    right: 1rem;

    transform: translateY(50%);

    pointer-events: none;
  }

  &__summary-list {
    margin: 1rem 0 !important;

    list-style-type: none;
  }

  &__summary-list-item {
    margin: 2rem 0;
  }

  &__summary-list-title {
    margin: 0 !important;
  }



  table,
  th,
  td {
    padding: 0;

    border: 0 solid;
    background-color: transparent;
  }

  th {
    min-width: 80px;
    padding-top: 1rem;

    font-family: $font-primary;
    font-size: 14px;
    color: $primary-color-alt;
    text-transform: uppercase;

    @include desktop {
      font-size: 21px;
      min-width: 160px;
    }
  }

  td {
    padding-bottom: 1.5rem;

    border-bottom: 1px solid $primary-color-alt;

    @include desktop {
      border-bottom: none;
    }
  }
}

.improved-ui .custom-form-block .training-apply-form {
  &__select-icon {
    bottom: 20px;
  }
}


@include until-lg {
  .training-apply-form__content {
    .freeform__column--submit{
      .freeform__submit{
        .form__submit {
          width: 100%;
          margin: 0 !important;
        }
      }
    }
  }
}
