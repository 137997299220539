.algolia-pagination {
  width: 100%;

  padding: 1.5rem 1rem;

  @include desktop {
    padding: 2rem 2rem 3rem;
  }

  &__list {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    max-width: 420px;
    list-style-type: none;
  }

  &__link {
    height: 40px;
    width: 100%;

    line-height: 40px;
    text-align: center;

    font-family: $font-secondary;
    color: $background-dark;

    &:hover {
      text-decoration: none;
    }
  }

  &__slot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;

    max-width: 42px;

    border: 1px solid $background-dark;

    transition: 0.2s;

    &:hover,
    &:focus,
    &--active {
      background-color: $primary-color;

      .pagination__link {
        color: $white;
      }
    }

    &:not(:first-of-type),
    &:not(:last-of-type) {
      margin-right: 0.5rem;
    }
  }

  &__arrow {
    @include gitp-arrow;

    display: block;

    position: relative;

    height: 40px;

    &::after {
      left: 50%;
    }

    &--left {
      &::after {
        top: 46%;

        transform: translate(-50%, -50%) rotate(180deg);
      }
    }

    &--right {
      &::after {
        top: 54%;

        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }
}
