.block--header-addendum {
  background: $background-light url($base-url+"blokjes.svg") no-repeat bottom left;
  background-size: auto 80%;

  .container,
  .np-container {
    @include tablet {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }

  .block__content {
    @include tablet {
      grid-column: 3 / 13;
    }
  }

  .buttons {
    display: flex;
    margin: 0 -16px;
    margin-top: 32px;
    width: calc(100% + 32px);
    a {
      margin: 0 16px;
      &.button--secondary {
        color: $text-primary;
      }
    }

    @include mobile-only {
      flex-direction: column;
    }
  }
}

.block--conversion-header-addendum {
  .block__content {
    @include tablet {
      grid-column: 3 / 8;
    }
  }
}

.block--support-header-addendum {
  .block__content {
    @include tablet {
      grid-column: 2 / 9;
    }
  }

  .block__button {
    @include mobile-only {
      margin: 32px 0 0;
    }
    @include tablet {
      grid-column: 10 / 14;
      align-self: center;
    }
  }

  h3 {
    margin-bottom: 4px;
  }
}
