.video-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  padding: 32px 0;
  height: 100%;
  width: 100%;
  z-index: 99;

  h3 {
    margin-bottom: 24px;
  }

  p {
    color: $form-newsletter;
    font-size: 16px;
  }

  .button--primary {
    margin-top: 32px;
    padding: 0 20px;
    width: 100%;

    &:after,
    &:before {
      display: none;
    }
  }

  &__image {
    width: 100%;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);

    .loader{
      color: official;
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .loader div{
      transform-origin: 40px 40px;
      animation: lds-spinner 1.2s linear infinite;
      &:after {
        content: " ";
        display: block;
        position: absolute;
        top: 3px;
        left: 37px;
        width: 6px;
        height: 18px;
        border-radius: 20%;
        background: #fff;
      }
      @for $i from 1 through 12 {
        &:nth-child(#{$i}) {
          transform: rotate( calc(#{$i} * 30deg) );
          animation-delay: calc(-1.1s + ( #{$i} * 0.1s) );
        }
      }
    }
  }

  &__content {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    width: calc(100vw - 40px);
    height: calc(100vh - 80px);
    @include desktop{
      height: auto;
      max-width: calc(100vh - 40px);
      max-height: calc(100vh - 40px);
    }
  }
  &__content{
    display: flex;
    justify-content: center;
    align-items: center;
    
    figure {
      position: relative;
      padding-top: 56.25%;
      width: 100%;
      aspect-ratio: 16 / 9;
    }
  
    iframe {
      position: absolute;
      top: 0;
      left: 0;
  
      // Add !important so iframe doesn't come up with its own width & height and overrules our beautiful styling
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      cursor: pointer;
    }
  }

  &__text {
    text-align: center;
    padding: 32px 24px;
  }
}
