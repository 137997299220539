.discount-label {
    &__container {
        width: max-content;
        background: $forest-green-light;
    }
    &__container--compact &__text {
        padding: 7px 34px;
    }
    &__text {
        display: block;
        width: max-content;
        font-size: 14px;
        padding: .5rem 1rem;
        @include desktop {
            font-size: 16px;
            padding: 0.75rem 2rem;
        }
    }
}