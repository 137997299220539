.article-hero {
  position: relative;
  z-index: 1;

  width: 100%;
  min-height: 468px;

  @include tablet {
    display: grid;
    grid-template-columns: $container-padding repeat(12, 1fr) $container-padding;
    grid-column-gap: $grid-gap;

    height: 80vh;
  }

  &__image-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    height: 100%;
    width: 100%;

    > img {
      height: 100%;
      width: 100%;

      object-fit: cover;
    }
  }

  &__content {
    position: relative;

    padding: 9rem 1.5rem 4rem;
    max-width: 300px;

    color: $white;

    @include tablet {
      grid-column: 2 / 10;

      padding-top: 10.5rem;
    }

    @include desktop {
      grid-column: 3 / 9;

      max-width: 460px;
    }
  }

  &__title {
    margin-bottom: 0.5rem;
  }

  &__intro {
    margin-bottom: 1rem;
  }

  &__cta {
    max-width: 220px;
  }
}
