.block--employee {
  .employee__image {
    grid-column: 4 / 6;

    img {
      border-radius: 100%;
    }
  }

  .employee__content {
    grid-column: 6 / 12;
    margin: 0;
    padding: 32px 20px;
    background: $background-light;

    @include tablet {
      // margin: -100px -100px 0;
      padding: 40px 50px;
    }
  }

  .employee-block__function {
    margin: 0 0 16px;
    color: $text-alt;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;

    @include tablet {
      font-size: 24px;
    }
  }

  .employee-block__mail {
    display: block;
    margin: 24px 0 0;
    font-size: 14px;
    word-break: break-word;

    .fas {
      margin: 0 6px 0 0;
    }
  }

  .employee-block__quote {
    margin: 20px 0 8px;
    padding: 0 0 0 16px;
    border-left: 1px solid $primary-color;
    font-size: 13px;
    line-height: 140%;

    @include tablet {
      font-size: 14px;
    }
  }
}
