.wysiwyg {
  h1,
  h2,
  h3,
  h4,
  h4,
  h6 {
    margin-bottom: 0.5em;
  }
  p {
    font-family: $font-tertiary;
    font-weight: normal;
    line-height: 1.44;
    font-size: 16px;
    color: $text-secondary;
    margin-bottom: 1em;
    @include desktop {
      font-size: 18px;
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }
  i {
    margin-right: 12px;
  }
  pre {
    margin-bottom: 25px;
    padding: 4px 8px;
  }

  ul,
  ol {
    margin: 12px 0 12px 27px;
  }

  li {
    margin: 0 0 8px;
    @include tablet {
      margin: 0 0 4px;
    }
  }

  //   Image
  figure {
    position: relative;
    display: flex;
    margin: 16px 0;
    width: 100%;

    @include tablet {
      margin: 32px 0;
    }

    img {
      width: 100%;
      max-width: unset;
    }
    figcaption {
      position: absolute;
      left: 0px;
      bottom: 0px;
      padding: 8px 16px 0;
      max-width: 100%;

      background: white;
      color: $text-alt;
      font-size: 15px;

      @include tablet {
        max-width: 85%;
      }
    }
  }

  //   Blockquote
  blockquote {
    margin: 16px 0;
    color: gray;
    font-size: 1.25em;
    line-height: normal;
    border-left: 4px solid gray;
    padding-left: 16px;
    @include tablet {
      margin: 32px 0;
    }
  }

  form {
    margin-bottom: 1em;
  }
}
