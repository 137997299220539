@mixin input {
  font-family: inherit;
  background: transparent;
  border: 1px solid $form-field-border;
  border-radius: 2px;
  padding: 18px 20px;
  color: $form-field-filled;
  display: block;
  font-size: 16px;
  font-weight: normal;
  outline: 0;
  transition: transition;
  width: 100%;
}

.grecaptcha-badge {
  z-index: 20;
}

input,
textarea,
select {
  @include input;
}

textarea {
  border-color: #6b7280;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.input {
  position: relative;
}

.input__field {
  &:focus ~ .input__label {
    transform: translateY(-50%);
    margin: 0 10px;
  }

  &::placeholder {
    color: $primary-color-alt;
  }
}

.input__label {
  font-size: 14px;
  cursor: text;
  display: inline-block;
  margin: 14px 10px;
  padding: 0 10px;
  top: 0;
  position: absolute;
  overflow: hidden;
  width: min-content;
  color: $primary-color-alt;
  background: $form-field-background;
  transition: all 0.1s linear;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input__icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: $secondary-color;

  & + .input__field {
    text-indent: 16px;
  }
}
