@import '@splidejs/vue-splide/css';

.splide {
  &__pagination {
    position: relative;
    bottom: 0;
    margin-top: 24px;
    gap: 16px;
    @include lg {
      margin-top: 35px;
    }
  }
  &__pagination__page {
    width: 16px;
    height: 16px;
    background: $blue-muted-bm-400;
    margin: 0;
    padding: 0;
  }
  &__pagination__page.is-active.is-active{
    background: $blue-b-670;
    transform: scale(1);
    opacity: 1;
  }
}