// Portrait cards + {block}
section[date-block-type="portrait-cards"] {
  & + section[date-block-type="landscape-cards"] {
    padding-top: 0;
  }

  &.has-bg.bg--white-to-black + &.has-bg.bg--white-to-black {
    @apply lg:pt-20;
  }

  &.has-bg.bg--white-to-black + &.has-bg.bg--black-to-white {
    @apply lg:pt-4;
    @apply lg:mt-[-3.5rem];
  }

  &.has-bg.bg--black-to-white + &.has-bg.bg--black-to-white {
    @apply lg:mt-10;
  }
}

