.block--employee-overview {
  .column {
    grid-column: 2 / -2;

    & + .column {
      margin-top: 80px;
    }
  }

  .employees {
    margin: 80px 0 0;

    @include tablet {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: $grid-gap;
      grid-row-gap: 40px;
    }
  }
}
