.block--single-feature {
  background-color: $text-secondary;
  .pre-title {
    grid-column: 2 / span 12;
  }
  .block__title {
    grid-column: 2 / span 6;
    font-size: 40px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: 42px;
    letter-spacing: -0.03em;
    color: $white;
    margin-bottom: 48px;
  }
  .block__description {
    margin-bottom: 48px;
    grid-column: 8 / span 6;
    font-size: 18px;
    color: $text-gray;
    *:not(a) {
      color: $text-gray;
    }
    a:hover,
    a:focus {
      color: $text-gray;
    }
  }
  .block__visual {
    grid-column: 2 / span 12;
    display: flex;
    margin: 0 -20px;

    @include tablet {
      margin: 0 #{-$grid-gap * 0.5};
    }

    img,
    video {
      width: 100%;
    }
  }
}
