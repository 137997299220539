.block--text-image {
  .block__header {
    width: 100%;
    grid-row: 1;
    grid-column: 8 / 14;
    margin-bottom: 25px;
    &.mobile-only {
      display: block;
      @include tablet {
        display: none;
      }
    }
    &.not-mobile {
      display: none;
      @include tablet {
        display: block;
      }
    }
  }
  .block__content {
    grid-row: 2;
    grid-column: 8 / 14;
    column-gap: $grid-gap;
    &.col--1{
      column-count: 1;
    }
    &.col--2{
      column-count: 2;
    }
  }
  .block__image {
    grid-row: 2;
    grid-column: 1 / 7;
  }

  .image {
    width: 100%;
    @include mobile-only {
      margin-bottom: 16px;
    }
  }

  .wysiwyg {
    margin: 0;
  }

  .button--primary {
    margin: 24px 0 0;
  }

  // Image alignment
  &.align-left.image-width-100,
  &.align-right.image-width-100 {
    .block__image {
      grid-column: 2/ 14;
    }
    .block__header,
    .block__content {
      display: none;
    }
  }
  &.align-left {
    &.image-width-50,
    &.image-width-33,
    &.image-width-25 {
      @include textImage(2, 9);
    }
    @include desktop {
      &.image-width-25,
      &.image-width-33 {
        @include textImage(2, 11);
      }
    }
    @include fullscreen {
      &.image-width-25 {
        @include textImage(2, 13);
      }
    }
  }
  &.align-right {
    &.image-width-50,
    &.image-width-33,
    &.image-width-25 {
      @include textImage(8, 1);
    }
    @include desktop {
      &.image-width-25,
      &.image-width-33 {
        @include textImage(6, 1);
      }
    }
    @include fullscreen {
      &.image-width-25 {
        @include textImage(4, 1);
      }
    }
  }
  &.align-center {
    .block__image {
      display: none;
    }
    .block__header,
    .block__content {
      grid-column: 5 / 11;
    }
  }

  // Text alignmet
  &.align-left {
    .block__header,
    .block__content {
      &.text-width-66 {
        grid-column: 2 / span 8;
      }
      &.text-width-100 {
        grid-column: 2 / span 12;
      }
    }
  }
  &.align-center {
    .block__header,
    .block__content {
      &.text-width-66 {
        grid-column: 4 / span 8;
      }
      &.text-width-100 {
        grid-column: 2 / span 12;
      }
    }
  }
  &.align-right {
    .block__header,
    .block__content {
      &.text-width-66 {
        grid-column: 6 / span 8;
      }
      &.text-width-100 {
        grid-column: 2 / span 12;
      }
    }
  }
}
