section[date-block-type="info-block"]{
    .grid-list-decotation{
        position: relative;
        padding-left: 10px;

        &:before{
            position: absolute;
            top: 9px;
            left: 0;

            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: currentColor;
        }
    }
}