.card {
  position: relative;
  display: flex;
  flex-direction: column;
  color: $text-dark;
  border-radius: 3px;
  box-shadow: $soft-shadow;
  background: $white;

  @include mobile-only {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  .card__image:last-child,
  .button--card:last-child {
    margin-top: auto;
    border-radius: 0 0 3px 3px;
  }

  //If there's an image above the button, no margin:auto
  .card__image {
    margin-top: auto;
    & + .button--card {
      margin-top: 0;
    }
  }

  .button--card {
    border-radius: 0 0 3px 3px;
    background: $background-dark;
    color: $text-white;

    &:hover {
      background: $primary-color;
      color: $white;
      text-decoration: none;
    }
  }

  .highlight {
    display: flex;
    align-items: center;
    position: absolute;
    top: -17px;
    padding: 0 16px;
    right: 24px;
    height: 34px;
    background: $primary-color;
    color: $text-white;
    font-family: $font-secondary;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }

  .title a {
    color: $text-dark;
  }

  .wysiwyg {
    margin-top: 24px;
  }

  .button--card {
    justify-content: flex-start;
    padding-left: 20px;
    max-width: 100%;

    @include tablet {
      padding-left: 40px;
    }
  }

  .filter {
    margin: 0 0 20px;
    font-size: 14px;
    color: $primary-color;
  }

  //Ff there's no filter above title
  .title {
    margin-top: 20px;
  }
  //If there is a filter above title
  .filter + .title {
    margin-top: 0;
  }
}

.card__content {
  padding: 32px 20px;

  @include tablet {
    padding: 32px 40px;
  }
}

.card__image {
  position: relative;
  overflow: hidden;
  padding: 50% 0 0;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
