.training-selection {
    position: relative;
    display: flex;
    flex-direction: column;
    height: max-content;


    @include desktop {
        padding: 2.2rem 2.2rem 1.5rem;
        background-color: hsl(0, 0%, 100%);
        -webkit-box-shadow: 0px 2px 20px 4px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 2px 20px 4px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        max-width: 425px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-top: 24px;
    }
    &__training{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &__training-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        font-family: $font-primary;
        color: $blue;
        margin: 0;
    }

    &__training-select {
        padding: 8px 40px 8px 8px;
        font-size: 16px;
        line-height: 24px;
        border: 2px solid $blue-muted-bm-400;
        border-radius: 2px;
        
        background-repeat: no-repeat;
        background-position: calc(100% - 10px);
        background-image: url($base-url+"icons/chevron-down-blue.svg");
        &:focus{
            border-color: $blue-b-400;
            --tw-ring-color: $blue-b-400;
        }
    }

    &__dropdown-title {
        font-family: $font-secondary;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        margin: 0;
        color: inherit;
        text-decoration: underline;
    }

    &__error {
        display: none;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        gap: 11px;
    }

    &__error-message {
        flex: 1;
        font-size: 12px;
        line-height: 15px;
    }

    &__error svg {
        display: block;
        width: 24px;
        height: 24px;
    }

    .discount-label {
        &__container {
            @include desktop {
                position: absolute;
                left: 0;
                top: 0;
                transform: translate(-2.2rem, -2.2rem);
            }
        }

        &__container + .price {
            margin-top: 20px;
        }
    }

    .dropdown {
        &__control {
            padding: 16px 0;
            opacity: 1;
        }
        &__control--active {
            opacity: 0;
            margin-bottom: -40px;
        }
        &__inner {
            padding: 0;
        }
        &__element {
            padding-bottom: 16px;
        }
    }
}