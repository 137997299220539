$sphereSize: 40px;

.growth-path-content{
    &__list-item {
        position: relative;
        padding-left: calc(16px + #{$sphereSize});
        padding-bottom: 32px;

        @include desktop {
            padding-bottom: 42px;
            padding-left: calc(50px + #{$sphereSize});
        }

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: #{$sphereSize};
            height: #{$sphereSize};
            border-radius: 50%;
            background: $white;
            border: 4px solid $blue-b-670;
            box-sizing: border-box;
            z-index: 1;
        }
        &:after{
            position: absolute;
            top: 0;
            left: calc(#{$sphereSize} / 2);
            content: "";
            width: 1px;
            height: 100%;
            background: $black;

        }

        &:last-of-type {
            padding-bottom: 0;
            &:after {
                content: none;
            }
        }
    }
}