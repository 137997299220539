.introduction-block {
  background: $background-light url($base-url+"blokjes.svg") no-repeat bottom left;
  background-size: auto 300px;

  // Grid
  .block__intro {
    position: relative;
    grid-column: 2 / 5;
    @include tablet {
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        top: -10%;
        height: 120%;
        left: auto;
        right: -16px;
        background: $primary-color;
        opacity: 0.5;
      }
    }
    @include container-min {
      &:before {
        right: -13px;
      }
    }
  }
  .block__content {
    grid-column: 6 / 14;
  }

  // Styling
  .block__intro {
    position: relative;
    @include tablet {
      padding-right: 10px;
    }
    .pre-link {
      text-decoration: underline;
    }
    .pre-link,
    .pre-text {
      position: relative;
      display: block;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.33;
      letter-spacing: normal;
      text-transform: uppercase;
      color: $primary-color;
      margin-bottom: 12px;
      @include tablet {
        font-size: 14px;
        line-height: 1.43;
        margin-bottom: 28px;
      }

      &:before {
        content: "";
        display: block;
        width: 7px;
        height: 7px;
        position: absolute;
        left: -25px;
        top: 4px;
        background: $text-primary;
        @include tablet {
          left: -23px;
          width: 12px;
          height: 12px;
        }
      }
    }
    .title {
      font-family: $font-primary;
      font-size: 22px;
      font-weight: bold;
      line-height: 1.06;
      color: $text-secondary;
      margin-bottom: 16px;
      @include tablet {
        line-height: 1.09;
        letter-spacing: -0.4px;
      }
    }
  }

  .employee-list {
    list-style: none;
    margin-top: 33px;
    @include tablet {
      margin-top: 25px;
    }
  }
}
