.submenu-navigation {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 65px;
  width: 100%;
  background: $white;
  height: 80px;
  z-index: 100000000002 !important; // use this to hide the menu
  transform: translateY(-100%);
  transition: all 0.2s;

  @include mobile-only {
    flex-direction: column;
    padding: 10px;

    &:not(.show-on-mobile) {
      display: none;
    }
  }

  @include tablet {
    height: 65px;
  }

  &.visible {
    transform: translateY(0);

    &:after {
      box-shadow: $regular-shadow;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: -66px;
    left: 66px;
    height: 66px;
    width: calc(100% - 132px);
    background: $white;

    @include tablet {
      width: calc(100% - 258px);
    }
  }

  &:after {
    content: "";
    pointer-events: none;
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    height: 250px;
    width: 100%;
    box-shadow: $shadow-invisible;
  }

  @include mobile-only {
    .button--primary {
      width: 100%;
    }
  }
}

.submenu-navigation__menu {
  display: flex;
  flex-direction: column;
  font-family: $font-secondary;

  @include mobile-only {
    display: none;
  }

  .title {
    position: relative;
    margin: 0 40px 0 0;
    font-size: 14px;
    font-family: $font-primary;
    text-transform: uppercase;
  }

  .title-decoration {
    top: 2px;
    background: rgba($primary-color, 0.5);

    &:before,
    &:after {
      background: rgba($primary-color, 0.5);
    }
  }

  .header-submenu__link {
    color: $text-primary;
  }
}

body.menu-visible {
  .submenu-navigation.visible {
    transform: translateY(66px);
    z-index: 100000000002 !important; // use this to hide the menu
  }
}


.page__training,
.page__growth-path {
  .header-submenu__link {
    color: $secondary-color;
  }
  .submenu-navigation.visible{
    .header-submenu__link {
      color: $primary-color;
    }
  }
}