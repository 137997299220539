.nav-main {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  display: grid;
  grid-template-columns: 65px 0 auto 0 0 0 0 65px;
  grid-template-rows: 65px;
  grid-column-gap: 13px;
  grid-row-gap: 1px;
  pointer-events: none;
  @include tablet {
    grid-template-columns: 65px 65px 112px 112px max-content auto 0 120px;
      &--closed.menu-items--static {
          .nav-form--search {
            display: flex !important;
            width: 100%;
            max-width: 420px;
            grid-area: 1/5/2/6;
            margin-left: auto;
            padding-right: 0;
            padding-left: 0;
          }
        .input__field {
          background: rgba(255, 255, 255, 0.3);
        }
      }
  }
  @include desktop {
    grid-template-columns: 65px 65px 65px max-content auto 112px 96px 120px;
    grid-template-rows: 65px;
    column-gap: 23px;
  }
  > * {
    pointer-events: auto;
  }
  &.hidden {
    transform: translateY(-65px); //own height + little offset for the shadow
  }
  &--open {
    z-index: 10000000001; // use this to hide the chat button
  }
}

// Buttons
[class^="nav-button"] {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $background-white;
  color: $text-dark;
  text-transform: uppercase;
  font-size: 20px;
  transition: all 0.2s;
  //Shadow in pseudo, so it doesn't fall over sibling-elements
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    box-shadow: $regular-shadow;
  }
  &:hover {
    text-decoration: none;
    opacity: 0.9;
  }
  &:focus {
    outline: none;
  }
  img {
    max-width: 38px;
  }
}

// Alignment
.nav-button--logo {
  grid-area: 1 / 1 / 2 / 2;
}
.nav-button--language {
  grid-area: 1 / 2 / 2 / 3;
}
.nav-form--search {
  grid-area: 1 / 4 / 2 / 4;
  width: 0;
  margin-top: 0 !important;
  display: none;
  @include tablet {
    padding: 0;
    grid-area: 1 / 5 / 2 / 5;
  }
  @include desktop {
    grid-area: 1 / 4 / 2 / 4;
  }
}
.nav-button--tel {
  grid-area: 1 / 2 / 2 / 3;
  @include tablet {
    grid-area: 1 / 3 / 2 / 3;
  }
  @include desktop {
    grid-area: 1 / -4 / 2 / -4;
  }
}
.nav-button--login {
  font-family: $font-secondary;
  grid-area: 1 / 3 / 2 / 4;
  @include tablet {
    grid-area: 1 / 4 / 2 / 4;
  }
  @include desktop {
    grid-area: 1 / -3 / 2 / -3;
  }
}
.nav-button--switch {
  grid-area: 1 / -2 / 2 / -2;
  i {
    font-size: 19px;
  }
  span {
    text-transform: none;
    display: none;
    @include desktop {
      display: block;
    }
  }
}

.nav-button--language,
.nav-button--tel,
.nav-button--login {
  display: none;
  @include desktop {
    display: flex;
  }
}

.menu-open .nav-main {
  top: 0 !important;
  .nav-button--tel {
    display: flex;
  }

  // Search
  $nav-left: 65px + 65px + 3 * 23px; // 199px
  $nav-right: 112px + 96px + 120px + 3 * 23px; // 397px
  $nav-difference: $nav-right - $nav-left;
  .nav-form--search {
    @include tablet {
      display: flex !important;
      align-items: center;
      width: 100%;
      padding-left: 0;
      max-width: 100%;
      grid-area: 1 / 5 / 2 / 8;
      margin: 0 auto;
    }
    @include desktop {
      padding-left: $nav-difference;
      max-width: calc(547px + #{$nav-difference});
      grid-area: 1 / 3 / 2 / 6;
    }
  }
}

.menu-open .nav-main,
.nav-main.hidden {
  [class^="nav-button"]:before {
    box-shadow: $shadow-invisible;
  }
}

.nav-button--logo {
  align-items: center;
  border-bottom-right-radius: 2px;

  svg {
    max-width: 38px;
    max-height: 100%;
  }

  img{
    max-width: 100%;
    max-height: 100%;
  }
}

.nav-button--switch {
  font-weight: bold;
  font-size: 15px;
  border-bottom-left-radius: 2px;
  @include until-desktop {
    width: 65px;
    margin-left: auto;
  }
}
.menu-open {
  @include until-desktop {
    .nav-button--switch {
      width: auto;
      margin-left: calc(2 * -13px);
      span {
        margin-right: 12px;
      }
    }
  }
}

.nav-button--language {
  align-items: center;
  padding: 8px 0;
  box-shadow: none;
  border: none;
  background: none;
  font-size: 15px;
  &:before {
    content: none;
    display: none;
  }
  @include mobile-only {
    display: none;
  }
}

.nav-button--switch {
  span {
    font-size: 15px;
    margin-right: 24px;
    color: $secondary-color;
  }
}

.nav-button--tel {
  font-family: $font-secondary;
  border-right: 1px solid $background-light;
  i {
    margin: 0 10px;
  }
  span {
    white-space: nowrap;
    margin: 0 10px 0 0;
  }
  @include desktop {
    display: flex;
    font-size: 14px;

    .fa-user {
      display: none;
    }
  }
}

.nav-button--tel,
.nav-button--login {
  box-shadow: none;
  border: none;
  background: none;
  font-size: 15px;
  font-weight: bold;
  color: white;
  &:before {
    box-shadow: none;
  }
  span {
    margin: 0;
    padding: 0;
  }
  i {
    margin: 0 10px 0 0;
    color: white !important;
  }
  @include on-event {
    span {
      text-decoration: underline;
      color: white;
    }
  }
}

.menu-open .nav-main {
  @include until-tablet {
    grid-template-columns: 65px 112px auto 0 0 0 0 120px;
  }
  .nav-button--logo {
    background: none;
    position: relative;
    @include mobile-only {
      &:after {
        content: "";
        position: absolute;
        display: inline-block;
        top: 50%;
        right: 0;
        width: 1px;
        height: 35px;
        background: rgba(126, 148, 146, 0.62);
        transform: translateY(-50%);
      }
    }
    svg {
      max-width: 38px;
      path {
        fill: white;
      }
    }
  }
  .nav-button--switch {
    background: none;
    position: relative;
    color: white;
    span {
      display: block;
      color: white;
    }
  }
}

// Stefano's ever changing design features >:(
.nav-main {
  max-width: 100vw;
  box-sizing: border-box;

  overflow: hidden;
  &.menu-items--static {
    left: 0;
    position: absolute !important;
    .nav-button--logo,
    // .nav-form--search,
    .nav-button--switch {
      display: none !important;
    }
    @include tablet {
      .nav-button--tel,
      .nav-button--login,
      .language-switcher {
        display: flex !important;
      }
    }
  }
  &.menu-items--dynamic {
    .nav-button--tel,
    .nav-button--login,
    .language-switcher {
      display: none !important;
    }
  }
}

.menu-open {
  .nav-main {
    @include mobile-only {
      grid-template-columns: 65px repeat(2, 1fr) 65px;
      .nav-button--tel {
        grid-area: 1 / 2 / 2 / 3;
      }
      .nav-button--login {
        grid-area: 1 / 3 / 2 / 4;
      }
      &.menu-items--dynamic {
        .nav-button--login,
        .nav-button--tel {
          font-size: 12px;
        }
        .nav-button--switch {
          span,
          i {
            font-size: 12px;
          }
        }
      }
    }

    &.menu-items--static {
      display: none;
    }
    &.menu-items--dynamic {
      .nav-button--login,
      .nav-button--tel {
        white-space: nowrap;
        display: flex !important;
      }
      @include tablet {
        .language-switcher {
          display: flex !important;
        }
      }
    }
  }
}

.page__cases,
.page__insight,
.page__news,
.page__employee,
.page__product,
.page__vacancy,
.page__training, 
.page__trainings-apply-form {
  &:not(.menu-open) {
    .nav-button {
      &--login,
      &--login i,
      &--tel,
      &--tel i {
        color: $secondary-color !important;
        @include on-event {
          color: $secondary-color !important;
          i,
          span {
            color: $secondary-color !important;
          }
        }
      }
    }
    .nav-search {
      &__inner i,
      &__inner input::placeholder,
      &__inner .input__field
       {
        color: $secondary-color !important;
      }
    }
    .language-switcher {
      li {
        &:before {
          color: $secondary-color !important;
        }
        :not(.active) {
          color: $secondary-color !important;
        }
      }
    }
  }
}
