.rating {
  display: flex;
  align-items: center;

  position: relative;

  color: $orange;

  span {
    display: inline-block;

    margin-top: 3px;
    margin-left: 0.3rem;

    color: $forest-green;
  }

  &__value {
    margin-right: 0.5rem;

    font-size: 24px;
    font-weight: 700;
  }

  &__stars {
    $percent: calc(var(--rating) / 5 * 100%);

    display: inline-block;
    font-size: 20px;
    font-family: Times;
    line-height: 1;

    &::before {
      background: linear-gradient(90deg, currentColor $percent, transparent $percent);
    }

    @for $index from 0 through 10 {
      @for $decimal from 0 through 9 {
        &--rating-#{$index}#{$decimal}::before {
          background: linear-gradient(90deg, currentColor calc( #{$index}#{$decimal} * 0.95%), transparent calc( #{$index}#{$decimal} * 0.95%));
        }
      }
    }

    &::before {
      content: "★★★★★";
      letter-spacing: 4px;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 1px currentColor;
    }
  }
}
