@use "sass:math";

.block--multi-flow {
  .block__content {
    padding-bottom: 24px;
    @include tablet {
      padding-bottom: 50px;
    }

    &.align-left {
      grid-column: 2/8;
    }

    &.align-middle {
      grid-column: 5/11;
    }
  }
  .block__list {
    grid-column: 2/14;
  }
  .block__links {
    grid-column: 2/14;
    padding-top: 0;
    @include tablet {
      padding-top: 25px;
    }
  }
}

.item-list {
  @include tablet {
    display: grid;
    grid-gap: inherit;
    grid-template-columns: repeat(12, 65px);

    &.align-middle {
      .item-list__item:first-child {
        grid-column: 4/7;
      }
    }
  }

  &.icon-arrow,
  &.icon-plus {
    .item-list__item:not(:last-child) {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 25px;
        right: math.div(-25px, 6);
        height: 24px;
        width: 30px;
        background-position: right;
        background-size: contain;
        z-index: 2;
        background-repeat: no-repeat;
        transform: translateX(50%);

        @include mobile-only {
          top: unset;
          right: unset;
          bottom: -6px;
          left: 50%;
          background-position: center;
          transform: translate(-50%, 50%);
        }
      }
    }
  }

  &.icon-arrow *:after {
    background-image: url($base-url+"icons/arrow.svg");
    @include mobile-only {
      transform: translate(-50%, 50%) rotate(90deg) !important;
    }
  }

  &.icon-plus *:after {
    background-image: url($base-url+"icons/plus.svg");
  }

  .item-list__item {
    margin: 0 -20px 12px;
    padding: 25px 22px;
    background: $background-white;
    box-shadow: $soft-shadow;

    @include tablet {
      margin: 0 (-25px*0.25) 25px;
      grid-column: span 3;
    }

    .title {
      color: $primary-color;
      font-size: 14px;
    }

    .redactor {
      margin-top: 12px;
      font-size: 16px;
      line-height: 22px;
    }
  }
}
