.dropdown {
    &__inner {
        position: relative;
        display: flex;
        flex-direction: column;
        background: $neutrals-white;
        z-index: 1;
        width: 100%;
        padding: 16px;
    }
    
    &__control {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        gap: 8px;
        width: 100%;
        margin: 0;
        text-align: left;
        color: $black-b-750;
        transition: color ease 0.3s;
        cursor: pointer !important;

        &:hover, &:focus {
          color: $blue-b-300;
        }
    }

    &__control--active &__icon {
      transform: rotate(180deg);
    }

    &__control > .training-summary__title{
      display: flex;
    }

    &__icon {
        width: 14px;
        transition: transform ease 0.3s;
    }

    &__element {
      width: 100%;
    }

    &__element:nth-of-type(n+2) {
      position: relative;
      &::before {
        display: block;
        content: "";
        width: 100%;
        height: 1px;
        margin: 1rem 0;
        background-color: rgb(215, 233, 232);
      }
    }
}
  
.dropdown-fade-enter-active, .dropdown-fade-leave-active {
  transition: opacity 0.5s;
}
.dropdown-fade-enter, .dropdown-fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}