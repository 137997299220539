.language-switcher {
  li {
    list-style: none;
    position: relative;
    display: flex;
    margin-left: 4px;
    font-weight: bold;
    &::before {
      content: "/";
      display: inline-block;
      color: white;
      padding-right: 4px;
      color: white;
      box-shadow: none;
    }
    & > * {
      color: white;
      font-family: $font-secondary;
    }
    &:first-of-type {
      padding-left: 0;
      &::before {
        content: none;
        display: none;
      }
    }
    a {
      margin: 0;
    }
  }
  .active {
    color: $primary-color;
    text-decoration: none;
    text-decoration: underline;
    margin: 0;
    cursor: default;
  }
  .disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
