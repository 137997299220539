//footer top
.footer-top {
  padding: 32px 0 50px;
  color: white;
  background: $background-dark;

  @include tablet {
    padding: 75px 0 50px;
  }

  .tablet {
    display: none;

    @include tablet {
      display: block;
    }
  }

  .divider {
    @include dots-divider;
    margin: 10px 0 20px;
    max-width: 184px;
    opacity: 0.6;
  }

  .navigation,
  .quick-links,
  .footer-top__bottom-primary,
  .footer-top__bottom-secondary {
    a {
      color: white;
      font-size: 15px;
      font-family: $font-secondary;
      font-weight: 700;
      text-decoration: underline;
    }
  }

  h4 {
    font-size: 14px;
  }

  .quick-links {
    a {
      position: relative;
      font-size: 14px;
      display: block;
      padding: 20px 0;
      font-weight: bold;
      line-height: 1.89;
      text-transform: uppercase;
      border-bottom: solid 1px #3a3f4b;

      @include tablet {
        padding: 0;
        padding-left: 28px;
        min-width: 190px;

        border: none;

        font-size: 18px;
      }

      &:hover {
        &:after {
          transform: rotate(0);
        }
      }

      @include gitp-arrow;

      &:after {
        font-weight: normal;
        right: -18px;

        @include tablet {
          right: unset;
          left: -18px;
        }
      }
    }
  }

  .navigation-links {
    display: flex;
    flex-direction: column;
    line-height: 180%;
  }

  .navigation-links__item {
    margin-bottom: 0.5rem;
  }
}

.footer-top__navigation-container {
  @include tablet {
    display: flex;
    justify-content: space-between;

    padding: 75px 0 50px;
    margin: 0 auto;
    max-width: 1440px;
  }
}

.footer-top__container {
  padding: 0px 40px;
}

.footer-top__column {
  @include tablet {
    margin: 0 1rem;
  }
}

.footer-top__newsletter {
  width: 100%;
  max-width: 615px;
  margin: 0 auto;

  @include desktop {
    max-width: 515px;
  }

  .custom-form-block {
    .freeform__label {
      height: 0;
    }
    .freeform__input {
      padding: 18px 20px;
    }
  }
}

.footer-top__bottom-primary {
  grid-column: 2 / 8;
}

.footer-top__bottom-secondary {
  width: 100%;

  @include tablet {
    padding: 0 1rem;
    margin: 0 auto;
    max-width: 1440px;

    text-align: right;
  }
}

.footer-top__bottom {
  grid-column: 2 / 14;
  grid-row: 3 / 4;
  align-self: self-end;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 32px;
  @include mobile-only {
    flex-direction: column;
  }
}

.footer-top__bottom,
.footer-top__bottom-primary,
.footer-top__bottom-secondary {
  a {
    text-align: left;
    display: inline-block;
    margin: 0 0 12px;
    min-width: 50%;
    text-decoration: underline;
    text-transform: uppercase;

    @include tablet {
      margin: 0 0 0 38px;
      min-width: 150px;
    }
  }
}

.footer-top__bottom-primary,
.footer-top__bottom-secondary {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 25px;
  @include tablet {
    grid-template-columns: repeat(12, 1fr);
  }
  @include desktop {
    grid-template-columns: repeat(12, 65px);
  }
  a {
    font-family: $font-secondary;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    padding: 8px 0;
    max-width: unset;
    grid-column: span 1;
    @include tablet {
      grid-column: span 3;
    }
  }
}

//Footer bottom
.footer-bottom {
  padding: 30px 0 0;
  font-family: $font-secondary;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;

  @include tablet {
    padding: 40px 0 0;
  }

  .container,
  .np-container {
    @include mobile-only {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media only screen and (min-width: 1280px) {
      grid-template-columns: 1fr repeat(14, calc($grid-width / 12) - $grid-gap) 1fr;
    }
  }

  img {
    min-width: 20px;
    max-height: 20px;

    @include tablet {
      min-width: 36px;
      max-height: 36px;
    }
  }

  a {
    display: flex;
    text-decoration: underline;
    color: $text-secondary;
    word-break: normal;
    line-height: 1.3em;
  }
}

.footer-bottom__info,
.footer-bottom__social-links,
.footer-bottom__navigation,
.footer-bottom__iso {
  display: flex;
  align-items: center;
  padding: 0 0 30px;

  @include tablet {
    padding: 0 0 40px;
  }
}

.footer-bottom__info {
  > *:not(:last-child) {
    margin-right: 20px;
  }

  @include mobile-only {
    flex: 1;
  }

  @include tablet {
    grid-row: 1 / 2;
    grid-column: 2 / span 6;

    > *:not(:last-child) {
      margin-right: 40px;
    }
  }
}

.footer-bottom__social-links {
  justify-content: flex-end;

  > *:not(:last-child) {
    margin-right: 20px;
  }

  @include mobile-only {
    flex: 1;
  }

  @include tablet {
    grid-row: 2 / 3;
    grid-column: span 6 / -2;
  }

  a {
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    line-height: 30px;
    text-decoration: none;
  }

  a:after {
    display: block;
    top: 0;
    right: 0;
    height: 100%;
    width: max-content;

    color: $primary-color;
    font-family: $font-awesome;
    font-size: 24px;

    @include tablet {
      font-size: 32px;
    }
  }

  .logo-- {
    &facebook:after {
      font-family: "Font Awesome 5 Brands";
      content: "\f082";
    }
    &twitter:after {
      font-family: "Font Awesome 5 Brands";
      content: "\e055";
    }
    &instagram:after {
      font-family: "Font Awesome 5 Brands";
      content: "\f081";
    }
    &linkedin:after {
      font-family: "Font Awesome 5 Brands";
      content: "\f08c";
    }
    &youtube:after {
      font-family: "Font Awesome 5 Brands";
      content: "\f431";
    }
    &website:after {
      content: "\f360";
    }
  }
}

.footer-bottom__navigation {
  @include mobile-only {
    flex-wrap: wrap;
    align-items: unset;
    width: 100%;

    a {
      margin: 0 0 8px;
      min-width: 100%;
    }
  }

  @include tablet {
    grid-row: 1 / 2;
    grid-column: span 6 / -2;
    justify-content: flex-end;
    > *:not(:last-child) {
      margin-right: 32px;
    }
  }
}

.footer-bottom__iso {
  @include mobile-only {
    flex-wrap: wrap-reverse;

    .footer-awards-logo {
      margin-right: 16px;
    }

    .footer-awards-text {
      margin-top: 16px;
    }
  }

  @include tablet {
    grid-row: 2 / 3;
    grid-column: 2 / span 6;
    flex-wrap: wrap-reverse;

    .footer-awards-logo {
      margin-left: 20px;
    }
  }
}


.footer{
  &-iframe{
    width: 100%;
    border:none;
  }
}