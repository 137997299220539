.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .link {
    background-color: $primary-color-darker;
    padding: 16px;
    border-radius: 2px;
    margin: 2px;
    width: auto;
    min-width: 36px;
    height: 36px;
    white-space: nowrap;
    @include tablet {
      margin: 4px;
      min-width: 48px;
      height: 48px;
    }
    display: flex;
    color: $text-white;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    box-shadow: 0 0 16px -8px rgba(0, 0, 0, 0.6);
    transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
    &:not(.link--disabled):hover {
      background-color: $primary-color-darker;
      transform: translateY(-6px);
      box-shadow: 0 5px 24px -6px rgba(0, 0, 0, 0.4);
    }
    &.link--disabled {
      background-color: $form-newsletter;
    }
  }
}
