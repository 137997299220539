.block--multi-feature {
  background: $background-alt;
  color: $text-alt;

  // -- layout --
  .block__content {
    grid-column: 2 / span 9;
  }
  .block__side-image {
    grid-column: span 2/-1;
    grid-row: 1 / span 2;
  }
  .block__list {
    grid-column: 2 / span 9;
  }

  // styling
  .content {
    .title {
      @include desktop {
        font-size: 34px;
        letter-spacing: -0.4px;
      }
      font-weight: bold;
      font-stretch: condensed;
      font-style: normal;
      color: $text-white;
      margin: 16px 0;
    }
  }

  .wysiwyg p {
    color: $text-alt;
  }

  .big-image {
    display: none;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
    @include tablet {
      display: block;
      height: calc(100% + 200px);
      margin: -100px 0;
      width: calc(100% + 12.5px);
      margin-left: -12.5px;
      overflow: hidden;
      position: relative;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      object-fit: cover;
    }
    &:before {
      content: "";
      z-index: 2;
      height: 100%;
      width: 100%;
      position: absolute;
      background-image: linear-gradient(
        to bottom,
        transparentize($background-alt, 0.35),
        transparentize($background-alt, 0.15)
      );
      @include tablet {
        background-image: linear-gradient(
          to right,
          transparentize($background-alt, 0.6),
          transparentize($background-alt, 0.9)
        );
      }
    }
  }
  .feature-list {
    grid-template-columns: repeat(9, 1fr);
    grid-gap: inherit;
    .feature-list__item {
      max-width: 300px;
      margin: 0;
      padding-top: 48px;
      grid-column: span 3;
      .feature-list__item__image {
        position: relative;
        height: 75px;
        width: 75px;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        & + .title {
          margin-top: 32px;
        }
      }
      .title {
        margin: 0 0 16px -20px;
        padding-left: 20px;
        border-left: 1px solid $primary-color;
        font-weight: bold;
        font-stretch: condensed;
        font-style: normal;
        color: $text-white;
        text-transform: uppercase;

        @include tablet {
          margin: 0 0 16px #{-$grid-gap * 0.5};
          padding-left: #{$grid-gap * 0.5};
        }
        @include desktop {
          font-size: 22px;
          letter-spacing: -0.4px;
        }
      }
      .description {
        font-size: 15px;
      }
    }
  }
}
