.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;

    height: 100vh;
    width: 100vw;

    background-color: rgba($secondary-color-darker, 0.25);
    backdrop-filter: blur(2px);
  }

  &__inner {
    position: relative;

    margin: 1rem;
    padding: 1.5rem;
    min-height: 312px;
    width: 100%;
    max-width: 634px;

    background-color: $white;
    box-shadow: 0px 4px 105px rgba(0, 0, 0, 0.25);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__toggle {
    position: absolute;
    right: 2rem;
    top: 2rem;

    background-color: transparent;

    color: $text-dark;
    font-size: 1.5rem;
  }
}
.confirm-button {
  background-color: white;
  color: #0f5384;
  position: absolute;
  bottom: 20px;
  &:hover {
    text-decoration: none;
  }
  &:hover &__text {
    text-decoration: underline;
  }
  &__arrow:after {
    content: "\f061";
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Font Awesome 5 Pro";
    font-size: 20px;
    height: 100%;
    width: 48px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  &:hover &__arrow {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition-duration: 0.3s;
  }
}