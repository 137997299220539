.block--related-insights {
  .insight {
    margin-bottom: 32px;
  }
  .block__content {
    grid-column: 2 / span 12;
  }

  @include tablet {
    .block--related-insights__list {
      display: grid;
      grid-column: 2 / span 12;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: inherit;
      .article {
        grid-column: span 6;
        @include desktop {
          grid-column: span 4;
        }
      }
    }
  }
}

.block--related-insights,
.related-insights__header {
  .block__header {
    grid-column: 2 / 7;
  }
}

.related-insights {
  padding: 0 0 50px;
  @include desktop {
    padding: 0 0 100px;
  }

  .article {
    @include mobile-only {
      margin-bottom: 32px;
    }
    grid-column: span 4;

    &:nth-child(3n + 1) {
      grid-column: 2 / span 4;
    }

    &:nth-child(3n) {
      grid-column: span 4 / -2;
    }
  }
}

.related-insights__header {
  .pre-title {
    text-align: left;
  }
}

.related-overview--link {
  display: none;
  text-emphasis: right;
  grid-column: 9 / 14;
  position: relative;
  margin: 0 0 16px;
  font-size: 12px;
  line-height: 112%;
  text-transform: uppercase;
  font-family: "Ropa Sans", sans-serif;
  font-weight: 700;
  text-decoration: underline;
  color: $primary-color;
  text-decoration: none;
  text-align: right;
  padding-right: 0;
  text-decoration: underline;
  &:after {
    text-decoration: none;
    content: "\f061";
    display: inline-block;
    margin: 0 0 0 12px;
    transition: all 0.2s;
    transform: rotate(45deg);
    font-family: $font-awesome;
  }
  @include tablet {
    display: block;
    width: auto;
    margin: 0 0 24px;
    font-size: 14px;
    text-align: right;
  }
  @include on-event {
    color: $text-dark;
    text-decoration: underline;
    &:after {
      transform: rotate(0deg);
    }
  }
}

.mobile-only {
  display: none;
  @include mobile-only {
    display: block;
    .related-overview--link {
      position: relative;
      z-index: 1;
      display: flex;
      text-align: center;
      width: auto;
      flex-direction: row;
      justify-content: center;
      padding-left: 40px;
      padding-right: 40px;
      width: 100%;
      @include tablet {
        display: none;
      }
    }
  }
}
