// copied from abstracts/_variables.scss
$base-url: "/web/assets/" !default;

@tailwind base;
@tailwind components;
@tailwind utilities;

// INCLUDE STYLING FROM ABSTRACTS
@import "./tailwind/abstracts/breakpoins.scss";
@import "./tailwind/abstracts/colors.scss";
@import "./tailwind/abstracts/customLists.scss";
@import "./tailwind/components/carousel.scss";
@import "./tailwind/components/data.scss";
@import "./tailwind/components/faq.scss";
@import "./tailwind/components/freeform.scss";
@import "./tailwind/components/landscapeCards.scss";
@import "./tailwind/components/portraitCards.scss";
@import "./tailwind/components/splideCarrousel.scss";

#vue3-app {
  max-width: 100dvw;
  overflow: clip;
}

.tw-select {
  background-repeat: no-repeat;
  background-position: right;

  &--white {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 20 20%27%3e%3cpath stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27M6 8l4 4 4-4%27/%3e%3c/svg%3e")
  }
  
  &--dark {
    background-image: url($base-url+"icons/chevron-down.svg");
  }
}

.tw-divider {
  background-image: radial-gradient(#fff 40%, transparent 60%);
  background-size: 5px 5px;
  background-position: 0 0;
  display: block;
  height: 15px;
  margin: 8px 0 16px;
  max-width: 184px;
  opacity: 0.6;
  width: calc(100% + 2rem);
}

.rotate-nested-icon-180 i,
.rotate-nested-icon-180 svg { 
  transform: rotate(180deg);
  transition: ease-in-out 0.3s;
}

.rotate-nested-icon-0 i,
.rotate-nested-icon-0 svg { 
  transform: rotate(0deg);
  transition: ease-in-out 0.3s;
}

h1, h2, h3, h4, h5, h6, .tw-title {
  font-family: "Roboto Condensed", sans-serif;

  span {
    white-space: pre;
  }
}
.tw-footer-links {
  position: relative;
  &:nth-of-type(n+2){
    &::after{
      position: absolute;
      top: -1rem;

      display: block;
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      background: #D6D6D6;
      
      @include lg {
        left: -1rem;
        top: 0%;
        transform: translateY(50%);
      }
    }
  }
}

@for $i from 1 through 14 {
  @include responsive("grid-cols-#{$i}"){
    grid-template-columns: repeat(#{$i}, 1fr);
  }
}

@for $i from 1 through 14 {
  @include responsive("col-span-#{$i}"){
    grid-column: span #{$i} / span #{$i};
  }
}

@for $i from 1 through 14 {
  @include responsive("col-start-#{$i}"){
    grid-column-start: #{$i};
  }
}


// Logos.twig
.logo-tile__shadow {
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.20));
}
.logo--active svg {
  display: block;
}

.bg-fade-to-right {
  background: linear-gradient(270deg, #FFF 37.07%, rgba(255, 255, 255, 0.00) 100%);
}

.bg-fade-to-left {
  background: linear-gradient(90deg, #FFF 37.07%, rgba(255, 255, 255, 0.00) 100%);
}

// FIXES WHITESPACE FOR SECTIONS WITH BACKGROUND
//
// default styles
// without background:
// padding: px-4 lg:px-0 py-7 lg:py-10
// margin: mx-0
//
// with background:
// padding: px-4 lg:px-0 py-10 md:py-20
// margin: my-7 md:my-10

header + section.has-bg {
  @apply mt-0;
}

section.has-bg + section.has-bg:not(.bg--white-to-black) {
  @apply -mt-10;
}

// section has white bottom half
// default bottom styling should apply
section.has-bg.bg--black-to-white {
  @apply mb-0 pb-7 lg:pb-10;
}

// section has white top half
// default top styling should apply
section.has-bg.bg--white-to-black {
  @apply mt-0 pt-7 lg:pt-10;
}