.article {
  position: relative;
  padding: 6px 6px;
  @include tablet {
    padding: 18px 18px;
  }
  @include desktop {
    padding: 24px 24px;
  }
  height: 100%;
  display: block;
  transform: translateY(0);
  box-shadow: 0 0 32px 16px rgba(0, 0, 0, 0);
  transition: box-shadow 0.2s, transform 0.2s;

  &:hover {
    background: $white;
    box-shadow: 0 0 96px -16px rgba(0, 0, 0, 0.2);
    transform: translateY(-6px);
    text-decoration: none;
  }
  .article__image {
    width: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
    padding-top: 100%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .article__type {
      position: absolute;
      top: 0;
      left: 0;
      padding: 8px 13px;
      background: white;
      color: $text-dark;
      font-size: 14px;
      font-family: $font-tertiary;
      text-transform: uppercase;
    }
  }
  .article__pre-title {
    margin: 16px 0;
    width: 125px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: $font-secondary;
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    color: $text-gray;
    display: flex;
    flex-wrap: wrap;
    .tag {
      width: 100%;
      color: $text-alt;
      & + .time,
      & + .date {
        margin-left: 0;
      }
    }

    .time,
    .date {
      white-space: nowrap;
    }
    i {
      color: inherit;
      margin-right: 8px;
    }
    span {
      margin: 0 8px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .article__title {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    font-stretch: condensed;
    letter-spacing: -0.4px;
    color: $text-secondary;
    font-family: $font-primary;
    @include tablet {
      font-size: 21px;
    }
  }
  .article__summary {
    padding: 16px 0;
    width: 100%;
    font-size: 13px;
    line-height: 19px;
    color: $text-secondary;
  }
  .article__summary {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: calc(3em + 32px);
  }
}
