.support-header {
  @include tablet {
    min-height: 60vh;
  }

  &.search-result {
    @include tablet {
      min-height: 40vh;
    }
  }

  .container,
  .np-container {
    @include tablet {
      grid-template-rows: 100px 120px 78px 1fr;
    }
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  h1 {
    @include mobile-only {
      margin: 32px 0 0;
    }
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 2 / 3;
      align-self: flex-end;
    }
  }

  .form-search--container {
    padding-bottom: 0;
    @include tablet {
      grid-column: 2 / 9;
      grid-row: 3 / 4;
      align-self: flex-end;
    }
  }

  .header-quicklinks {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 32px;

    @include mobile-only {
      flex-direction: column;
    }

    @include tablet {
      margin: 0 0 72px;
      grid-column: 2 / 14;
      grid-row: 4 / 5;
      align-self: flex-end;
    }

    a {
      margin: 8px 0 0;
      text-decoration: underline;
      text-transform: uppercase;
      color: $text-white;
      font-family: $font-secondary;
      font-weight: 700;

      @include tablet {
        font-size: 18px;
        width: 100%;
        max-width: calc(100% / 3);
      }
    }
  }
}
