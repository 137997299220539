.pie-chart-donut {
  position: relative;

  width: 80px;
  height: 80px;

  &__percentage-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-weight: 700;
    font-size: 1.5rem;
    white-space: nowrap;
  }
}
