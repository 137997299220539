.training-details-apply {
  position: relative;

  padding: 1rem;

  @include tablet {
    padding: 1rem 0;
  }

  @include desktop {
    position: sticky;
    top: 60px;
    margin-top: -7.5rem;

    // makes sure details content doesn't shift when expanded
    height: 545px;
    z-index: 2;
    pointer-events: none;

    &--has-sub-nav {
      top: 80px;
    }

  }

  &__layout {
    position: relative;

    grid-column: 2/14;

    pointer-events: visible;

    @include tablet {
      max-width: 432px;
    }

    @include desktop {
      grid-column: 9/14;
    }
  }

  &__inner {
    @include desktop {
      padding: 2.2rem 2.2rem 1.5rem;

      background-color: $white;
      box-shadow: 0px 2px 20px 4px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
    }
  }

  &__type {
    display: inline-block;

    padding: 0.5rem 1rem;
    margin: 1.5rem 0;

    font-family: $font-secondary;
    font-size: 14px;
    color: $white;

    background-color: $forest-green;

    @include desktop {
      position: absolute;
      top: 4rem;
      right: 2.2rem;
    }
  }

  &__info {
    @include desktop {
      margin-top: 1rem;

      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__usps {
    display: block;

    @include desktop {
      display: none;
    }
  }

  &__discount-label {
    display: none;

    font-family: $font-primary;
    font-size: 16px;
    font-weight: normal;

    @include desktop {
      display: block;

      position: absolute;
      top: 0;
      left: 0;

      padding: 0.75rem 2rem;

      background-color: $forest-green-light;

      font-size: 16px;
    }
  }

  &__rating {
    display: flex;
    align-items: center;
  }

  &__rating-link {
    position: relative;

    &:hover,
    &:focus {
      text-decoration: none;

      &::after {
        content: "";
        display: block;

        position: absolute;
        bottom: -4px;

        width: 100%;
        height: 1px;
        background-color: $orange;
      }
    }
  }

  &__form-title,
  &__form-text {
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
  }

  &__form-title + &__form-text {
    margin-top: -10px;
  }


  &__reviews-counter {
    margin-left: 0.5rem;

    color: $orange;
  }

  &__request-info-form {
    max-width: 400px;
    margin-top: 0.5rem;
  }

  &__select-label {
    font-family: $font-secondary;
    font-weight: 700;
    font-size: 16px;

    @include tablet {
      display: block;
      margin-top: 1rem;
    }
  }

  &__select {
    margin-top: 0.5rem;
    height: 50px;

    font-family: $font-secondary;
    font-size: 14px;
    text-transform: uppercase;

    -webkit-appearance: none;
    appearance: none;

    @include desktop {
      background-color: $background-light;
    }
  }

  &__select-container {
    position: relative;
    max-width: 400px;

    .fa-chevron-down {
      position: absolute;
      bottom: 24px;
      right: 1rem;
      height: 18px;
      width: auto;
      border: none;
      text-align: center;
      line-height: 50px;
      pointer-events: none;
      transform: translateY(50%);
    }
  }

  &__break {
    width: 100%;
    max-width: 400px;
    height: 1px;
    margin: 1rem 0;

    background-color: $line-color;

    @include desktop {
      &--mobile-only {
        display: none;
      }
    }
  }

  &__quotation {
    text-transform: uppercase;
  }

  &__success {
    text-align: center;
    font-size: 1.4rem;
    color: $primary-color;
    padding-top: 2rem;
  }

  .button--apply,
  .button--quotation {
    display: block;

    padding: 0.85rem;
    margin-top: 1rem;
    width: 100%;

    text-align: center;
    text-transform: uppercase;

    cursor: pointer;

    transition: 0.3s color;
  }

  .button--quotation {
    font-family: $font-secondary;
    font-size: 14px;
    text-align: center;

    border-radius: 2px;

    transition: 0.3s ease;

    &:hover {
      background-color: $primary-color;
      color: $white;
    }
  }

  .button--quotation-toggle {
    justify-content: center;

    width: 100%;

    font-family: $font-secondary;
    text-align: center;

    &:hover {
      color: $primary-color;
    }
  }

  &--sticky {
    position: fixed;
    bottom: 0;

    width: 100%;

    background-color: $white;
    box-shadow: 0px 2px 20px 4px rgba(0, 0, 0, 0.25);

    z-index: 2;

    @include desktop {
      display: none;
    }

    &__form {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0 1rem;
    }

    &__request-info-container {
      display: flex;
      justify-content: space-between;

      padding: 1rem;
    }

    .price-indicator {
      margin: 0;
    }
  }

  &__inner .custom-form-block  .form__content,
  &__inner .custom-form-success {
    box-shadow: none;
    padding: 0;
  }

}

.sticky-training-details-apply {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  background-color: $white;
  box-shadow: 0px 2px 20px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
  
  @include tablet {
    padding: 1rem 0;
  }
  
  @include desktop {
    display: none;
  }

  &__form {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 1rem;
  }

  .price-indicator {
    margin: 0;
  }
}