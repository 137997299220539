.featured-article-header {
  min-height: 60vh;
  padding-bottom: 45px;
  
  .container,
  .np-container{
    align-items: flex-start;
    justify-content: flex-end;
  }

  .block__content {
    @include tablet {
      grid-column: 2 / 13;
      grid-row: 2 / 3;
      align-self: center;
      margin: 0 0 24px;
      max-width: 521px;
    }

    h1 {
      margin: 0;
      font-size: 32px;

      @include tablet {
        font-size: 50px;
      }
    }

    .wysiwyg {
      color: white;
      margin-top: 12px;
    }

    .buttons {
      margin-top: 17px;
      & > * {
        margin-top: 8px;
      }
    }
  }
  
  .image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
