// Styling for:
// _partials/relatedContent.twig
// _partials/blocks/relatedContent.twig
// _partials/relatedNews.twig
// _partials/blocks/relatedNews.twig
// _partials/relatedInsights.twig
// _partials/blocks/relatedInsights.twig

// Global
.block--related-insights,
.block--related-news {
  & > .container,
  & > .np-container {
    padding: 0;
    grid-template-columns: 0;
    gap: 0;
  }
}
.related-content {
  grid-column: 2 / span 12;
  padding: 40px 0;
  & > .container,
  & > .np-container {
    padding-bottom: 10px;
  }
}

// Header
.related-content__header {
  grid-column: 2 / span 12;
  width: 100%;
  display: flex;
  flex-direction: row;
  .block__header {
    flex: 1;
  }
  .block__link-container {
    display: none;
    @include tablet {
      display: inline-block;
    }
  }
}

// List
.related-content__list {
  grid-column: 2 / span 12;
  width: 100%;
  @include tablet {
    display: grid;
    grid-column: 2 / span 12;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: inherit;
  }
  .content-list__item {
    grid-column: span 6;
    @include desktop {
      grid-column: span 4;
    }
    @include mobile-only {
      margin-bottom: 32px;
    }
  }
}

// Link
.related-content__link-container {
  grid-column: 2 / span 12;
  &.mobile-only {
    width: 100%;
    display: flex;
    justify-content: center;
    @include tablet {
      display: none;
    }
  }
}
.related-content__overview-link {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: max-content;
  font-size: 12px;
  line-height: 112%;
  text-transform: uppercase;
  font-family: $font-secondary;
  font-weight: 700;
  text-decoration: underline;
  color: $primary-color;
  &:after {
    text-decoration: none;
    content: "\f061";
    display: inline-block;
    margin: 0 0 0 12px;
    transition: all 0.2s;
    transform: rotate(45deg);
    font-family: $font-awesome;
  }
  @include tablet {
    display: block;
    width: auto;
    margin: 0 0 24px;
    font-size: 14px;
    text-align: right;
  }
  @include on-event {
    color: $text-dark;
    text-decoration: underline;
    &:after {
      transform: rotate(0deg);
    }
  }
}
