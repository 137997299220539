.cards-large {
  margin: 40px 1.5rem;

  @include tablet {
    display: grid;
    grid-template-columns: $container-padding repeat(12, 1fr) $container-padding;
    grid-column-gap: $grid-gap;

    margin: 5rem 1.5rem;
  }

  &__content {
    @include tablet {
      grid-column: 2 / 14;
    }

    @include desktop {
      grid-column: 3 / 13;
    }
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__description {
    margin-bottom: 1rem;
    max-width: 620px;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}
