.form-search--container {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  @include tablet {
    grid-column: 2 / 9;
    padding-bottom: 100px;
    align-self: flex-end;
  }
}

.form-search {
  display: flex;
  flex-direction: row;

  background: $background-white;
  border: 1px solid $form-field-border;
  border-radius: 2px;
  overflow: hidden;
  &.wrap {
    flex-wrap: wrap;
    @include tablet {
      flex-wrap: nowrap;
    }
  }
  @include tablet {
    height: 58px;
  }
  @include mobile-only {
    margin: 8px 0 32px;
  }

  input[type="search"] {
    border: none;
    padding: 14px 20px;
    height: inherit;
    width: 100%;
    border-radius: 2px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: none;
    font-size: 14px;
    background: $background-white;
    border-bottom: 1px solid $form-field-border;

    @include tablet {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }

    @include desktop {
      padding: 0 25px;
    }

    &::placeholder {
      color: $primary-color-alt;
    }
  }
  .type-selector {
    width: calc(100% - 80px - 10px);
    outline: none;
    border: none;
    border-radius: 0;
    padding: 14px 10px 14px 10px;
    @include tablet {
      border-left: 1px solid $form-field-border;
      width: 200px;
    }
    & + button {
      margin-left: 10px;
    }
  }

  button {
    height: inherit;
    display: block;
    position: relative;
    border-radius: 0;
    border-bottom-right-radius: 2px;
    min-width: 80px;
    background: $primary-color;
    @include tablet {
      width: 60px;
      border-radius: 2px;
      background: $primary-color;
      border-left: none;
    }
    @include desktop {
      width: 100%;
      max-width: 132px;
    }
    @include on-event {
      color: $white;
      text-decoration: none;
      background: $primary-color-darker;
    }
    .text {
      display: none;
      @include desktop {
        background: inherit;
        color: $text-white;
        text-transform: uppercase;
        font-size: 14px;
        font-family: $font-secondary;
        display: block;
      }
    }
    .icon {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      color: $text-white;
      @include desktop {
        display: none;
      }
    }
  }
}
