// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any,
// and all typgraphic styling (H1 - H6, p, strong, em, i, blockquote)
// -----------------------------------------------------------------------------
//
// When preferred or needed, @font-face declarations can also be moved to
// separate _fonts.scss
// -----------------------------------------------------------------------------

// INLCUDE FONTS
// @font-face {
//   font-family: "font-name";
//   src: url("assets/fonts/font-name.woff2") format("woff2"),
//     url("assets/fonts/font-name.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
// }

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;500;600;700&&family=Roboto:wght@400;500;600;700&family=Roboto:wght@400;500;600;700&family=Ropa+Sans&display=swap");
$font-primary: "Roboto Condensed", sans-serif;
$font-secondary: "Ropa Sans", sans-serif;
$font-tertiary: "Roboto", sans-serif;
$font-awesome: "Font Awesome 5 Pro";

:root {
--font-primary: #{$font-primary};
--font-secondary: #{$font-secondary};
--font-tertiary: #{$font-tertiary};

--font-roboto-condensed: #{$font-primary};
--font-ropa-sans: #{$font-secondary};
--font-roboto: #{$font-tertiary};
}

body {
  font-family: "Roboto", sans-serif;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 144%;
  word-break: break-word;
  @include tablet {
    font-size: 18px;
  }
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;

  span {
    white-space: pre;
  }
}

h1,
.h1 {
  font-size: 20px;
  line-height: 105%;
  @include tablet {
    font-size: 32px;
  }

  @include desktop {
    font-size: 40px;
  }

  &--large {
    font-size: 32px;
  }
}

h2,
.h2 {
  font-size: 17px;
  line-height: 105%;

  @include tablet {
    font-size: 28px;
  }
  @include desktop {
    font-size: 34px;
  }
}

h3,
.h3 {
  font-size: 16px;
  line-height: 110%;

  @include tablet {
    font-size: 22px;
  }
}

h4,
h5,
.h4,
.h5 {
  font-family: $font-secondary;
  font-weight: bold;
}

h4,
.h4 {
  font-size: 8px;
  line-height: 112%;
  text-transform: uppercase;
  @include tablet {
    font-size: 16px;
  }
}

h5,
.h5 {
  font-size: 14px;
  line-height: 170%;
  text-transform: uppercase;
}

.hero-title {
  font-size: 2rem;
  line-height: 120%;

  @include desktop {
    font-size: 50px;
  }
}
