.block--analytics {
  .h2 {
    color: $text-alt;
    white-space: nowrap;
  }

  @include mobile-only {
    *:nth-child(3) {
      margin-top: 32px;
    }
  }

  @include tablet {
    .container,
    .np-container {
      &:after {
        content: "";
        position: absolute;
        top: 100px;
        left: 50%;
        height: calc(100% - 200px);
        width: 1px;
        background: $primary-color;
      }
    }
  }
  .block--content {
    grid-column: 2 / 14;

    @include tablet {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: $grid-gap;
      max-width: 100%;
      gap: 24px;
      padding: 0;
    }
    > * {
      padding: 0 10px;
    }
  }

  .text__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 450px;

    @include tablet {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 24px;
      max-width: unset;
    }

    & > * {
      flex: 1;
    }

    .h2 {
      font-size: 46px;
    }

    @include tablet {
      .tiny-text {
        font-size: 65px;
      }
      .small-text {
        font-size: 45px;
      }
      .medium-text {
        font-size: 30px;
      }
      .big-text {
        font-size: 24px;
      }
    }
    
    @include desktop {
      .tiny-text {
        font-size: 77px;
      }
      .small-text {
        font-size: 66px;
      }
      .medium-text {
        font-size: 44px;
      }
      .big-text {
        font-size: 33px;
      }
    }
  }
}
