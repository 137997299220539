.block--list-navigation {
  .block__content {
    grid-column: 2 / 8;
  }

  .block__list {
    padding: 40px 0 0;
    @include tablet {
      grid-column: 2/8;
    }
    .button-list__item {
      a {
        max-width: unset;
      }
    }
  }

  .list-title {
    font-size: 16px;
    grid-column: 1 / 11;
    @include tablet {
      font-size: 20px;
    }
  }

  .button-list {
    list-style: none;
    grid-column: 1 / 11;
  }

  .text {
    margin-top: 12px;
  }

  .button-list__item {
    margin: 6px 0;

    @include tablet {
      margin: 10px -13px;
    }

    a:hover {
      @include button--primary;
      color: $white;
    }
  }

  .button--special {
    color: #2f3441;
    text-decoration: underline;
    justify-content: flex-start;
    padding: 0;
    @include tablet {
      padding: 0 13px;
    }
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    picture{
      width: 100%;
      height: 100%;
    }

    img {
      height: inherit;
      max-height: inherit;
      width: inherit;
      object-fit: cover;
    }
  }

  *:not(.pre-title, .button--special) {
    text-shadow: 0 0 10px #ffffff;
  }
  .pre-title,
  .button--special {
    text-shadow: 1px 1px 15px #ffffff, 0px 0px 5px #ffffff;
  }
  .button--special:hover {
    text-shadow: none;
  }

  &.colorscheme-dark {
    *:not(.pre-title) {
      color: $text-white;
    }
    *:not(.pre-title, .button--special) {
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5),
        -1px -1px 3px rgba(0, 0, 0, 0.5);
    }
    .pre-title,
    .button--special {
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3),
        -1px -1px 3px rgba(0, 0, 0, 0.3);
    }
    .button--special:hover {
      text-shadow: none;
    }
  }
  ::before,
  ::after {
    text-shadow: none;
  }
}
