.training-summary {
    display: flex;
    flex-direction: column;
    height: max-content;
    height: 100%;
    width: 100%;
    max-height: calc(100dvh - 120px);

    @include desktop {
        position: sticky;
        top: 80px;
        justify-content: space-between;
        height: min-content;
        min-height: 520px;
        max-width: 423px;
        max-height: unset;
        margin-left: auto;
        padding: 24px;
        background: $neutrals-white;
        overflow: visible;    
    }

    &__container {
        padding: 16px 0;
        position: relative;
        grid-column: 2/14;
        
        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 0;
            height: 100%;
            width: 100dvw;
            background-color: $neutrals-n-80;
            transform: translateX(-50%);
            @include desktop {
                left: 0;
                transform: none;
            }
        }
        
        @include desktop {
            grid-column: 8 / 14;
            height: 100%;
            padding: 26px 0;
            
            &:before {
                height: 100%;
                width: 50dvw;
            }
        }

    }
    &__container--desktop{
        display: none;
        @include desktop {
            display: flex;
        }
    }
    &__container--mobile{
        display: flex;
        @include desktop {
            display: none;
        }
    }

    &__content{
        display: flex;
        flex-direction: column;
        height: max-content;
        gap: 0;
    }
    
    &__title {
        display: none;
        font-family: $font-secondary;
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;
        margin: 0;
        color: $neutrals-n-500;

        @include desktop {
            display: flex;
            font-size: 24px;
            line-height: 32px;
            font-weight: 700;
            padding: 10px 0;
        }
    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 16px 0 16px;
    }
    &__training{
        display: flex;
        flex-direction: column;
        gap: 0;
        width: 100%;
        border-bottom: 1px solid $blue-muted-bm-400;
        padding: 0 0 18px;
        @include desktop {
            padding: 0 0 16px;
        }
    }
    &__training-title {
        color: $neutrals-n-500;
        font-family: $font-tertiary;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        text-transform: none;
        margin: 0;
    }
    &__run-description{
        font-family: $font-tertiary;
        color: $neutrals-n-500;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0;
    }
    &__run-time {
        display: flex;
        gap: 8px;
        color: $neutrals-n-200;
        font-family: $font-tertiary;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0;
    }
    &__run-time svg {
        color: $neutrals-n-350;
        width: 20px;
        height: 20px;
    }

    &__price-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 10px;
        width: 100%;
        text-align: right;
        margin-top: 24px;
    }
}