#vue3-app {
  section[date-block-type="faq"] {
    .question__answer {
      a[href^='tel:'],
      a[href^='mailto:'] {
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        &:before {
          display: block;
          width: 1.2em;
          height: 1.2em;
          min-width: 1.2em;
          min-height: 1.2em;
        }
      }

      a[href^='tel:'] {
        &:before {
          content: url($base-url+ "icons/phone.svg");
        }
      }

      a[href^='mailto:'] {
        &:before {
          content: url($base-url+ "icons/envelope.svg");
        }
      }
    }
  }
}
