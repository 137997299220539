.block--cards {
  @include mobile-only {
    .container,
    .np-container {
      padding: 0 0 50px;
    }
  }

  .filters {
    margin: 40px 0 0;

    .h5 {
      font-weight: normal;
    }
  }

  .card-filter {
    margin: 8px 8px 0 0;
    padding: 4px 8px;
    background: $line-dark;
    color: $white;
    font-family: $font-secondary;
    font-size: 14px;
    text-transform: uppercase;
    outline: none;

    &.card-filter--active {
      background: $primary-color;
    }
  }

  .block__background {
    grid-column: 1 / 15;
    grid-row: 1 / 2;
    margin: -100px 0 -300px;
    background: $background-dark url($base-url+"blokjes-light.svg") no-repeat
      bottom left;
    background-size: auto 300px;

    @include mobile-only {
      display: none;
    }
  }

  .block__content {
    text-align: center;
    color: $text-secondary;

    @include mobile-only {
      padding: 50px 20px 250px;
    }

    @include tablet {
      grid-column: 3 / 13;
      grid-row: 1 / 2;
    }

    .description {
      margin: 32px 0 0;
      color: $text-secondary;
      & *:not(a) {
        color: $text-secondary;
      }
    }

    text-align: center;
    color: $text-white;

    @include mobile-only {
      color: $text-white;
      padding: 50px 20px 250px;
      background: $background-dark url($base-url+"blokjes-light.svg") no-repeat
        bottom left;
      background-size: auto 300px;
    }

    .description {
      color: $text-white;
      & *:not(a) {
        color: $text-white;
      }
    }

    .filters {
      .h5 {
        color: $text-white;
      }
    }

    @include tablet {
      grid-column: 5 / 11;
    }
  }

  .block__cards {
    margin: -200px 20px 0;

    @include tablet {
      grid-column: 3 / 13;
      grid-row: 2 / 3;
      margin: 64px 0 0;
    }
  }

  .cards {
    @include tablet {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 25px;
      grid-row-gap: 25px;
    }
  }
}
