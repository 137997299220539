.quiz {
  display: flex;
  flex-direction: column;

  margin-top: 65px;
  min-height: 100vh;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @include desktop {
    align-items: center;

    margin-top: 8rem;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;

    position: relative;

    box-shadow: $regular-shadow;
    background-color: $white;
    border-radius: 2px;

    @include desktop {
      flex: 0 0 0;
      flex-direction: row;

      width: 100%;
      max-width: 1096px;
    }

    &--no-shadow {
      margin-top: 100px;

      box-shadow: none;
    }
  }

  &__double-column {
    padding: 2.5rem 1rem;

    &--left {
      background-color: $background-blue;

      color: $white;

      @include desktop {
        padding: 80px 40px;
        width: 332px;
        min-height: 540px;
      }
    }

    &--right {
      flex: 1;
      position: relative;

      height: 100%;

      @include desktop {
        padding: 80px 56px 40px;
      }
    }

    &--reduced-padding {
      @include desktop {
        padding: 40px;
      }
    }

    &--column-large {
      @include desktop {
        flex: 1;

        width: initial;

        // Shrinks sibling column
        ~ section {
          flex: 0;

          min-width: 420px;
        }
      }
    }
  }

  &__description-tag {
    display: inline-block;

    padding: 0.3rem 1rem;
    margin-bottom: 1rem;

    border: 1px solid $secondary-color;
    border-radius: 2px;

    color: $text-dark;
    font-family: $font-secondary;
    font-size: 14px;
    text-transform: uppercase;
  }

  &__description {
    max-width: 372px;
    margin-bottom: 1rem;

    img {
      display: block;

      margin: 10px 0;
      max-width: 250px;
    }
  }

  &__title {
    font-family: $font-secondary;
  }

  &__dots {
    @include dots-divider;

    width: 90px;
    margin: 1rem 0;

    background-image: radial-gradient($forest-green-alt 40%, transparent 60%);

    &--large {
      width: 220px;
      margin: 1.5rem 0;
    }
  }

  &__back-button-wrapper {
    margin-top: 2rem;
    min-height: 2.25rem;
  }

  &__back-button {
    background-color: transparent;

    font-family: $font-secondary;
    font-weight: 700;
    color: $text-dark;

    > span {
      margin-left: 0.5rem;
    }

    &--top {
      position: absolute;
      left: 1rem;
      top: 2rem;
    }
  }

  &__background {
    position: absolute;
    z-index: 0;
    bottom: 50px;
    left: 0;

    width: 100%;
    height: calc(100% - 50px);

    background: linear-gradient(180deg, rgba(246, 249, 252, 0) 0%, #f6f9fc 92.71%, #e5edf5 100%);

    pointer-events: none;

    animation: animate-background;
    animation-duration: 0.75s;
    animation-direction: forwards;
    animation-timing-function: ease-out;
    transform-origin: bottom center;

    @keyframes animate-background {
      0% {
        transform: scaleY(0);
      }
      100% {
        transform: scaleY(1);
      }
    }
  }

  &__questions-container {
    height: 100%;
    width: 100%;
  }

  &__questions {
    display: flex;
    align-items: center;
    flex-direction: column;

    height: calc(100% - 50px);

    font-family: $font-secondary;
    color: $text-secondary;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;

    @include desktop {
      max-width: 768px;
      width: 100%;
    }
  }

  &__legend {
    position: absolute;
    right: 1rem;
    top: 1.5rem;

    padding: 0.5rem;

    background-color: transparent;

    color: $primary-color;
    text-transform: uppercase;
    font-weight: 700;

    > i {
      margin-left: 0.5rem;
    }
  }

  &__legend-title {
    margin-bottom: 1.5rem;

    text-align: left;
    color: $primary-color;
    font-size: 1.5rem;

    > i {
      display: block;

      margin-bottom: 0.5rem;
    }
  }

  &__legend-answers {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    list-style-type: none;
  }

  &__legend-definitions {
    display: grid;
    grid-template-columns: minmax(88px, max-content) 1fr;

    text-align: left;
    font-size: 18px;

    > dt {
      text-transform: none;
    }

    > dd {
      text-transform: lowercase;
      font-weight: 400;
    }
  }

  &__questions-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    position: relative;
    z-index: 1;

    padding: 1.5rem 1rem;
    margin-top: 1.5rem;

    background-color: $white;
    box-shadow: 0px 5px 8px rgba(4, 23, 37, 0.2);
    border-radius: 2px;

    @include tablet {
      width: 432px;
      padding: 24px 40px 56px;
    }
  }

  &__step-counter {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  &__question {
    margin-top: 1.5rem;

    font-size: 1.5rem;
    line-height: 2rem;

    @include desktop {
      margin-top: 40px;

      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  &__answers-wrapper {
    justify-self: flex-end;

    width: 100%;
    height: 50px;
    padding: 0 1rem;
  }

  &__answers {
    display: flex;
    justify-content: space-between;

    max-width: 432px;
    margin: 1rem auto 0;

    list-style-type: none;
  }

  &__answer {
    z-index: 1;
  }

  &__button-answer {
    padding: 0.9rem 1rem;

    background-color: $primary-color;
    border-radius: 2px;

    font-weight: 700;
    color: $white;

    transition: 0.3s background-color;

    @include on-event {
      background-color: $primary-color-darker;
    }

    &--is-selected {
      background-color: $secondary-color;
    }
  }

  &__results-calc-title {
    font-size: 18px;
    line-height: 24px;
  }

  &__results-data-visualisations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;

    margin: 1.5rem 0;
    max-width: 350px;
  }

  &__results-chart {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    // swap title and chart, so it's better readable for screen readers
    flex-direction: column-reverse;
  }

  &__results-category-title {
    font-size: 1rem;
    font-weight: 400;
    color: $forest-green-dark;
    text-transform: none;
    text-align: center;
  }

  &__results-modal {
    color: $text-dark;
  }

  &__results-modal-title {
    max-width: 90%;

    font-size: 1.5rem;
    line-height: 2rem;

    @include desktop {
      font-size: 2rem;
      line-height: 42px;
    }
  }

  &__results-modal-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__results-modal-input-group {
    display: flex;
    gap: 1.5rem;

    input[type="checkbox"] {
      max-width: 18px;
      max-height: 18px;
      min-width: 18px;
      min-height: 18px;
    }
  }

  &__results-modal-success-message {
    img {
      margin: 2.5rem 0 1rem;
    }
  }
}
