.general-header {
  min-height: 60vh;
  
  &.with-search {
    .container,
    .np-container {
      padding-bottom: 32px;
      @include tablet {
        padding-bottom: 72px;
        grid-template-rows: 100px 120px 78px 1fr;
      }
    }

    h1 {
      max-width: 720px;
      @include mobile-only {
        margin: 32px 0 0;
      }
      @include tablet {
        grid-column: 2 / 8;
        grid-row: 2 / 3;
        align-self: self-end;
      }
    }

    .form-search--container {
      padding-bottom: 0;
      @include tablet {
        grid-column: 2 / 9;
        grid-row: 3 / 4;
        align-self: flex-end;
      }
    }
  }

  .container,
  .np-container {
    align-items: flex-start;
    justify-content: flex-end;
  }

  .header-submenu {
    grid-column: 2 / 14;
    grid-row: 1 / 2;
    margin: 90px 0 0;
  }

  &:not(.with-search) h1 {
    margin: auto 0 -6px;
    font-size: 32px;

    @include tablet {
      grid-column: 3 / 13;
      grid-row: 2 / 3;
      align-self: self-end;
      margin: 0 0 -12px;
      font-size: 56px;
    }
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
