.vue-video-player {
  grid-column: 2 / span 12;
  width: 100%;

  .time {
    height: 24px;
    text-shadow: 0 0 34px rgba(0, 0, 0, 0.55);
    font-family: $font-secondary;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    color: $white;
    margin: 12px 16px;
  }
  .bar {
    transition: width 0.1s linear;
    height: 3px;
    background-color: $primary-color;
  }
  .video {
    position: relative;
    display: block;

    .overlay {
      cursor: pointer;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all 0.2s;
      background: rgba(0, 0, 0, 0.55);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 110%;
      &.hidden {
        opacity: 0;
      }
    }

    .button {
      pointer-events: none;
      position: absolute;
      top: 28px;
      left: 28px;
      overflow: hidden;
      justify-content: center;
      padding: 0 0 0 60px;
      z-index: 5;
      height: 48px;
      min-width: 180px;
      width: max-content;
      border-radius: 24px;
      background: none;
      text-align: center;
      color: $white;
      font-family: $font-secondary;
      text-transform: uppercase;

      @include mobile-only {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.pause {
        display: none;
      }

      &:focus {
        outline: none;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 48px;
        height: 48px;
        border-radius: 24px;
        background-color: $white;
        background-image: url($base-url+"icons/arrow.svg");
        background-size: auto 16px;
        background-repeat: no-repeat;
        background-position: 16px;
        transition-delay: background-image 0.1s;
        transition: left 0.2s ease-in-out, transform 0.2s ease-in-out;
      }
    }
    video {
      min-width: 100%;
      height: auto;
      // height: intrinsic;
    }
  }
}
