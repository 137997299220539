.card-large {
  padding: 1rem;
  max-width: 1100px;
  margin: 0 auto;

  background-color: $white;
  box-shadow: $regular-shadow;

  @include tablet {
    display: flex;
    gap: 2.5rem;
  }

  @include desktop {
    padding: 40px 5rem;
  }

  &__image-container {
    width: 100%;
    max-height: 246px;
    margin-bottom: 1rem;
    overflow: hidden;

    @include tablet {
      height: 100%;
      min-width: calc(50% + 2rem);
      min-height: 410px;
      margin-bottom: 0;
    }

    .image, 
    img {
      width: 100%;
      min-height: inherit;
      object-fit: cover;
    }
  }

  &__title {
    margin-bottom: 1rem;

    font-size: 24px;
    line-height: 150%;

    @include tablet {
      font-size: 32px;
    }
  }

  &__description {
    margin-bottom: 1rem;

    > p {
      margin-bottom: 1rem;

      font-size: 1rem;
      line-height: 1.5rem;

      @include tablet {
        font-size: 18px;
      }
    }
  }
}
