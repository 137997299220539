.block--conversion {
  background: $background-light url($base-url+"blokjes.svg") no-repeat bottom left;
  background-size: auto 80%;

  .container,
  .np-container {
    @include tablet {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }

  .block__content {
    @include tablet {
      grid-column: 2 / 10;
    }
  }

  .block__buttons {
    @include mobile-only {
      margin: 32px 0 0;
    }
    @include tablet {
      grid-column: 11 / 14;
      align-self: center;
    }
  }

  .wysiwyg {
    margin-top: 8px;
    white-space: break-spaces;
  }

  .button--secondary {
    margin-top: 8px;
  }

  &.colorscheme-dark {
    background: $background-dark url($base-url+"blokjes-light.svg") no-repeat bottom left;
    background-size: auto 80%;
    color: $text-white;

    .wysiwyg,
    .wysiwyg p {
      color: $text-alt;
    }
  }
}
