// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.homepage-header,
.general-header,
.simple-header,
.conversion-header,
.article-header,
.contact-header,
.support-header,
.service-header,
.form-header,
.featured-article-header {
  &.without-image {
    min-height: 378px;
  }
  &.light-background {
    background: $background-light url($base-url+"blokjesFullwidth.svg") no-repeat bottom right;
    background-size: auto 378px;

    .title-decoration {
      background: rgba($primary-color, 0.5);

      &:before,
      &:after {
        background: rgba($primary-color, 0.5);
      }
    }

    h1,
    h2,
    h3 {
      color: $text-alt;
    }

    h4,
    h5 {
      color: $primary-color;
    }

    p,
    .wysiwyg {
      color: $text-dark;
    }

    .header-submenu__link {
      color: $text-primary;
    }
  }

  .header-top-row {
    margin-top: 90px;
    justify-content: space-between;
    grid-column: 2 / 14;
    grid-row: 1 / 2;
    .breadcrumbs,
    .breadcrumb {
      margin: 0;
      & + .header-submenu {
        margin: 20px 0 0;
      }
    }
  }

  .header-top-title {
    position: relative;
    font-size: 14px;
    font-family: $font-primary;
    text-transform: uppercase;

    @include mobile-only {
      display: none;
      margin-bottom: 12px;
      padding: 0 0 0 28px;
    }
  }
}

.homepage-header,
.general-header,
.simple-header,
.conversion-header,
.contact-header,
.support-header,
.form-header,
.featured-article-header {
  position: relative;
  z-index: 2;
  display: flex;
  background: $background-dark;

  &:before,
  &:after,
  .image-container:before,
  .image-container:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 61%, rgba(0, 0, 0, 1));
  }

  .image-container {
    @include dots-background;
  }

  @include dots-background;

  &.light-background {
    &:before,
    &:after {
      display: none;
    }
  }

  .container,
  .np-container {
    position: relative;
    z-index: 2;
    height: inherit;
  }

  .image img,
  video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  video {
    display: none;
    @include tablet {
      display: block;
    }
  }

  &.align-center {
    @include tablet {
      text-align: center;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    max-width: 720px;
    color: #fff;
  }

  p {
    margin: 24px 0 0;
    font-size: 17px;
    @include desktop {
      margin: 24px 0 0;
      font-size: 18px;
    }
  }

  .header-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 24px auto 0;
    width: 100%;
    gap: 0;
    max-width: 720px;
    & > * {
      width: calc(50% - 8px);
    }
    @include tablet {
      gap: 26px;
      margin: 40px 0 0;
      & > * {
        width: auto;
      }
    }
    a {
      margin: 4px;
    }
  }

  .button--secondary {
    color: $text-white;
  }

  &.video {
    video {
      display: flex;
    }
    > *:not(video),
    &:before,
    &:after {
      display: none;
    }
    .header-video-button {
      display: flex;
      .fa-times {
        margin: 0 12px 0 4px;
      }
    }
  }
}

.header-submenu {
  position: relative;
  z-index: 12;
  display: none;
  @include tablet {
    display: flex;
  }

  @include mobile-only {
    flex-direction: column;
  }
}

.header-submenu__link {
  margin: 8px 0 0;
  color: $text-white;
  font-size: 14px;
  font-family: $font-secondary;
  font-weight: 700;
  text-transform: uppercase;

  @include tablet {
    margin: 30px 20px 0 0;
    font-size: 18px;
  }

  @include desktop {
    margin: 0 40px 0 0;
  }

  &:hover {
    color: $text-white;
  }

  &:last-child {
    margin-right: 0;
  }
}

.title-decoration {
  position: absolute;
  top: -0;
  left: 0;
  height: 20px;
  width: 20px;
  background: rgba($white, 0.5);

  @include tablet {
    top: 2px;
    left: -35px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    height: 9px;
    width: 9px;
    background: rgba($white, 0.5);
  }

  &:after {
    content: "";
    top: 8px;
    left: 8px;
  }
}

.header {
  .breadcrumb {
    & + .header-submenu {
      @include tablet {
        margin: 130px 0 0;
      }
    }
  }
}
