.training-details-content-blocks {
  // padding: 2rem 0;

  &__section {
    position: relative;
    margin-bottom: 2rem;

    @include desktop {
      margin-bottom: 4rem;
    }

    &--background {
      padding-top: 2rem;
      padding-bottom: 2rem;

      &::after {
        display: none;

        content: "";
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: $background-light url($base-url+"blokjes.svg") no-repeat bottom left;
        background-size: auto 300px;

        z-index: -1;

        @include desktop {
          display: block;
        }
      }
    }

    img {
      margin: 1.5rem 0 0;
    }

    figcaption {
      color: $forest-green;
      font-size: 14px;
    }

    ul {
      list-style-type: none;
      margin-left: 1.7rem;

      @include desktop {
        margin-left: 1.6rem;
      }

      li {
        position: relative;

        margin-bottom: 1rem;

        font-size: 18px;

        &::before {
          content: "";
          position: absolute;
          top: 9px;
          left: -26px;
          height: 7px;
          width: 7px;
          background: $primary-color;

          @include tablet {
            top: 7px;
            left: -24px;
            height: 12px;
            width: 12px;
          }
        }
      }
    }
  }

  .scroll-anchor{
    position: absolute;
    top: -80px;
    left: 0;
    opacity: 0;
  }

  &__section-inner {
    grid-column: 2/14;

    max-width: 525px;

    @include desktop {
      width: 100%;
    }
  }

  &__double-column {
    @include desktop {
      display: flex;
      justify-content: space-between;

      .training-details-content-blocks__text {
        width: 42%;
      }
    }
  }
}
