$black: #000000;
$white: hsl(0, 0%, 100%);

$neutrals-white: #FFFFFF;
$neutrals-n-50: #F3F6F9;
$neutrals-n-75: #F0F0F0;
$neutrals-n-80: #F9F9F9;
$neutrals-n-100: #A1B4BB;
$neutrals-n-200: #8B8B8B;
$neutrals-n-300: #707070;
$neutrals-n-350: #646464;
$neutrals-n-400: #525252;
$neutrals-n-500: #3D3D3D;

$blue: #105485;
$blue-b-100: #EDF6FD;
$blue-b-200: #68ADDE;
$blue-b-250: #5A8EB5;
$blue-b-300: #2F8ED2;
$blue-b-400: #287CB8;
$blue-b-500: #1368A4;
$blue-b-670: #105485;

$blue-muted-bm-100: #F4F7FA;
$blue-muted-bm-400: #B1C5CC;
$blue-muted-bm-500: #6792B0;

$yellow-yellow-500: #F5C000;

$black-b-750: #2A2E38;

$carousel-gradient: linear-gradient(180deg, #FFF 11.58%, #F3F4F6 100%);

:root {
  --black: #{$black};
  --black-b-750: #{$black-b-750};

  --white: #{$white};

  --neutrals-white: #{$neutrals-white};
  --neutrals-n-50: #{$neutrals-n-50};
  --neutrals-n-75: #{$neutrals-n-75};
  --neutrals-n-100: #{$neutrals-n-100};
  --neutrals-n-200: #{$neutrals-n-200};
  --neutrals-n-300: #{$neutrals-n-300};
  --neutrals-n-350: #{$neutrals-n-350};
  --neutrals-n-400: #{$neutrals-n-400};
  --neutrals-n-500: #{$neutrals-n-500};

  --blue: #{$blue};
  --blue-b-100: #{$blue-b-100};
  --blue-b-200: #{$blue-b-200};
  --blue-b-250: #{$blue-b-250};
  --blue-b-300: #{$blue-b-300};
  --blue-b-400: #{$blue-b-400};
  --blue-b-500: #{$blue-b-500};
  --blue-b-670: #{$blue-b-670};

  --blue-muted-bm-100: #{$blue-muted-bm-100};
  --blue-muted-bm-400: #{$blue-muted-bm-400};
  --blue-muted-bm-500: #{$blue-muted-bm-500};

  --yellow-yellow-500: #{$yellow-yellow-500};

  --carousel-gradient: #{$carousel-gradient};
}