// DIVICE SIZES
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xl2: 1536px;

// BASIC QUERIES
@mixin sm {
  @media screen and (min-width: #{$sm}) {
    @content;
  }
}
@mixin md {
  @media screen and (min-width: #{$md}) {
    @content;
  }
}
@mixin lg {
  @media screen and (min-width: #{$lg}) {
    @content;
  }
}
@mixin until-lg {
  @media only screen and (max-width: #{$lg - 1}) {
    @content;
  }
}
@mixin xl {
  @media screen and (min-width: #{$xl}) {
    @content;
  }
}
@mixin xl2 {
  @media screen and (min-width: #{$xl2}) {
    @content;
  }
}

@mixin responsive( $element ) {
  .#{$element} {
    @content;
  }
  .sm\:#{ $element } {
    @include sm {
      @content;
    }
  }
  .md\:#{$element} {
    @include md {
      @content;
    }
  }
  .lg\:#{$element} {
    @include lg {
      @content;
    }
  }
  .xl\:#{$element} {
    @include xl {
      @content;
    }
  }
  .\2xl\:#{$element} {
    @include xl2 {
      @content;
    }
  }
}