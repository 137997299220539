.employee {
  display: block;
  grid-column: span 3;

  @include mobile-only {
    margin: 32px 0 0;
  }

  &.small {
    @include mobile-only {
      display: grid;
      grid-template-columns: 46px 1fr;
      grid-template-rows: 1fr 1fr;
      grid-column-gap: 8px;

      a {
        align-self: flex-end;
      }
    }

    .employee__image {
      margin: 0;
      padding: 0;
      height: 46px;
      width: 46px;

      @include mobile-only {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        align-self: center;
      }

      @include tablet {
        margin: 0 0 8px;
      }
    }
    .image {
      border-radius: 100%;
    }

    .employee__name,
    .employee__function {
      font-size: 15px;
      font-weight: normal;
      font-family: $font-tertiary;
      text-transform: none;

      @include mobile-only {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        display: flex;
      }
    }

    .employee__function {
      margin: 0;
      color: $primary-color;
      @include mobile-only {
        grid-row: 2 / 3;
      }
    }
  }
}

.employee__image {
  position: relative;
  display: block;
  margin: 0 0 16px;
  padding: 100% 0 0;
  height: fit-content;

  @include tablet {
    margin: 0 0 24px;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.employee__name {
  color: $text-dark;
}

.employee__function {
  margin: 4px 0 0;
  color: $text-alt;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.employee__mail {
  display: none;
  margin: 4px 0 0;
  color: $primary-color;
  font-size: 14px;
  word-break: break-word;
}

.employee__quote {
  margin: 20px 0 8px;
  padding: 0 0 0 16px;
  border-left: 1px solid $primary-color;
  font-size: 13px;
  line-height: 140%;
}

.employee__wysiwyg {
  display: none;
}
