.form-naw {
  &__consent-label {
    display: flex !important;
    align-items: center;

    color: $text-dark !important;
    font-family: $font-primary;
    font-size: 14px !important;

    > input {
      margin-right: 1rem !important;
    }
  }

  &__consent-small {
    display: block;
    margin-top: -2rem;

    font-size: 12px;
    line-height: 1.5;
  }

  // Freeform
  .freeform-row {
    display: block;

    @include tablet {
      display: flex;
      align-items: flex-end;
    }
  }

  .freeform-label {
    margin-bottom: 1rem;

    color: $forest-green-dark;
    font-weight: 400 !important;
    font-size: 18px;
    line-height: 24px;
  }

  .freeform-input {
    padding: 18px 16px !important;

    background-color: $background-light !important;

    &::placeholder {
      font-size: 14px;
      font-family: $font-primary;
      color: $forest-green-dark;
    }

    &[type="checkbox"] {
      width: 18px !important;
      height: 18px !important;
      &:checked {
        background-color: currentColor !important;
      }
    }
  }

  .freeform-required::after {
    content: initial !important;
  }

  button[type="submit"] {
    width: 100%;
    margin-top: 1.5rem;

    @include desktop {
      width: initial;
      margin-top: 2rem;
    }
  }
}
