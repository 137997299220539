.training {
    &__outer {
        position: relative;
        width: 100%;
    }
    &__container{
        grid-column: 2 / span 12;
        grid-row: 1 / span 1;
        @include tablet {
            grid-column: 2 / span 12;
        }
        @include desktop {
            height: 100%;
            grid-column: auto / span 5;
        }
    }
    &__content-blocks {
        grid-column: 2 / span 12;
        grid-row: 2 / span 1;
        @include desktop {
            grid-row: 1 / span 1;
            grid-column: 2 / span 7;
            width: 100%;
            max-width: 575px;
        }
    }

    // disable grid for every block in training contentblocks
    // This is needed, to allow correct content layout in the 2 columns layout (trainings/growth-path detail page)
    &__content-blocks .md\:grid-cols-block-grid,
    &__content-blocks .lg\:grid-cols-block-grid,
    &__content-blocks .xl\:grid-cols-block-grid,
    &__content-blocks .grid,
    &__content-blocks .md\:grid,
    &__content-blocks .lg\:grid,
    &__content-blocks .xl\:grid,
    &__content-blocks .container, 
    &__content-blocks .np-container{
        display: flex !important;
        flex-direction: column;
    }

    &__spacing {
        grid-row: 1/1;
        grid-column: 2/3;
    }
    
    &__form {
        width: 100%;
        pointer-events: visible;
        
        @include desktop {
            position: sticky;
            top: calc(80px + 7.5rem);
            transform: translateY(-4.5rem);
            z-index: 2;
            max-width: 432px;
        }
    }

    &__container .training-details-related-trainings{
        grid-column: 2 / 14;
        position: relative;
    }
}