.block--search-bar {
  background: $background-light url($base-url+"blokjes.svg") no-repeat bottom left;
  background-size: auto 80%;

  .container,
  .np-container {
    @include tablet {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }
  .block__content {
    @include tablet {
      grid-column: 2/14;
      .form-search--container {
        max-width: 780px;
      }
    }
  }
  .title {
    margin-bottom: 24px;
  }
  .form-search--container {
    grid-column: unset;
    padding-bottom: 0;
  }
}
