.block--form {
  display: block;
  z-index: 1;
  background: $background-light url($base-url+"blokjes.svg") no-repeat bottom left;
  background-size: auto 300px;
  .form-block__content {
    grid-column: 3/13;
  }
}

.container--form {
  background: white;
  @include tablet {
    background: none;
  }
}

.page__trainings-apply-form {
  .block--form {
    background: url($base-url+"blokjes.svg") no-repeat bottom left;
    background-size: auto 300px;
  }
}

// Form - Basic{
.custom-form-block {
  margin-bottom: 25px;
  padding: 0;
  background: transparent;
  font-family: $font-tertiary;
  .form__content {
    background: $white;
    padding: 8px;

    @include tablet {
      padding: 40px;
      box-shadow: 0 0 64px -24px rgba(0, 0, 0, 0.2);
    }
  }

  .freeform__submit {
    @include until-tablet {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;

      button,
      a {
        flex: 1;
        text-align: center !important;
      }
    }
  }

  input,
  select,
  textarea {
    border-radius: 2px;
    background: $background-light;
  }
}

// Form - Pagination
.custom-form-block {
  .freeform-pages,
  .form__navigation {
    margin: -25px -25px 25px;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    background: transparent;
    max-width: 100%;
    @include tablet {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
  .freeform-pages li,
  .form__navigation--item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    margin: 0;
    background: transparent;
    margin-bottom: 0;
    min-width: 25%;
    min-height: 40px;
    padding: 8px 8px;
    max-width: 100%;
    border: none;
    background: white;
    transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s;
  }
  .freeform-pages li b {
    margin: -8px -8px;
  }
  .freeform-pages li b,
  .form__navigation .form__navigation--current {
    padding: 8px 8px;
    width: calc(100% + 2 * 8px);
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    &.active {
      color: white;
     background-color: $primary-color;
    }
  }
  .form__navigation--item__text {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    color: inherit;
    margin: 0;
  }
}
// Form - Rows
.custom-form-block {
  .freeform__row {
    margin: 0;
    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }
    &:last-of-type {
      .freeform__column {
        &:last-child {
          width: 100%;
          margin: 0;
          margin-bottom: 0;
        }
        @include tablet {
          margin-bottom: 0;
        }
      }
    }
  }
}
// Form - Columns
.custom-form-block {
  .freeform__row {
    &:first-of-type {
      padding-top: 25px;
    }
    .freeform__column {
      flex: 1;
      padding: 0;
      margin: 0;
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      @include tablet {
        margin-right: 12px;
        &:last-child {
          margin-right: 0;
        }
      }
      &--service {
        margin-bottom: 0;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0 0 0.7em 0;
        color: $text-secondary;
      }
      p,
      ol,
      ul {
        font-size: 16px;
        margin: 0 0 0.7em 0;
      }
      ol,
      ul {
        &:not(.freeform__errors):not(.opinion-scale-scales),
        &:not(.ff-errors):not(.opinion-scale-scales) {
          margin-left: 21px;
        }
      }
    }
  }
}

// Form field - container (custom)
.custom-form-block {
  .freeform__field {
    position: relative;
    z-index: 1;
    input.ff-has-errors {
      border-color: $form-field-error;
    }
    &.field-error {
      input {
        border-color: $form-field-error;
      }
    }
    &.freeform__html,
    &.freeform__rich_text {
      *:not(a) {
        color: inherit;
      }
    }
  }
}
// Form field - Labels
.custom-form-block {
  .freeform__label {
    font-size: 14px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    user-select: none;
    background: none;
    transform: none;
    white-space: normal;
    width: 100%;
    margin: 0;
    font-family: $font-primary;
    font-weight: normal !important;
    color: $text-secondary;
    &.required,
    &.freeform-required {
      &:after {
        content: "*";
        margin-left: 5px;
        color: $text-secondary;
      }
    }

    &.freeform__textarea,
    .freeform__text {
      line-height: 1em;
    }
  }

  textarea {
    width: 100% !important;
  }

  .freeform-input-only-label {
    color: black;
  }
}
// Form field - Inputs
.custom-form-block {
  // Basic
  .freeform__radio_group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .freeform__label {
      margin-bottom: 8px;
    }
    .freeform__field {
      margin-right: 22px;
    }
  }
  .freeform__input {
    &::placeholder,
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
      color: $forest-green-dark;
      transition: color 0.2s ease;
      transition-delay: 0.1s;
    }

    &:not(:placeholder-shown),
    &:focus {
      &::placeholder {
        color: $primary-color-alt;
      }
      &::-webkit-input-placeholder {
        color: $primary-color-alt;
      }
      &:-moz-placeholder {
        color: $primary-color-alt;
      }
      &::-moz-placeholder {
        color: $primary-color-alt;
      }
      &:-ms-input-placeholder {
        color: $primary-color-alt;
      }
    }
  }
  // Radiobuttons / checkboxes
  .freeform__column {
    .freeform-input-only-label,
    label:nth-of-type(n + 2) {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      cursor: pointer;
      user-select: none;
      background: none;
      transform: none;
      white-space: normal;
      width: 100%;
      margin: 0;
      font-size: 16px;
      color: $text-secondary;
      width: 100%;

      .freeform__input {
        &[type="checkbox"],
        &[type="radio"] {
          position: relative;
          width: 0px;
          height: 0px;
          margin: 0;
          padding: 0;
          opacity: 0;
          &:checked {
            & + .marker:after {
              transform: scaleY(1);
            }
          }
        }
        &[type="radio"] {
          &:checked {
            & + .marker:after {
              opacity: 1;
              transform: translate(-50%, -50%);
            }
          }
        }

        &[type="checkbox"],
        &[type="radio"] {
          & + .marker {
            position: relative;
            width: 0px;
            height: 0px;
            padding-left: 25px;
            margin: 0;
            &:before,
            &:after {
              content: "";
              display: block;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              z-index: 0;
              width: 18px;
              height: 18px;
              transition: all 0.2s;
            }
            &:before {
              border: 2px solid $form-field-border;
              border-radius: 1px;
            }
            &:after {
              left: 4px;
              top: -5px;
              width: 10px;
              height: 10px;
              background: $form-field-placeholder;
              transform: rotate(0deg) scaleY(0);
            }
          }
        }

        &[type="checkbox"] + .marker {
          padding-left: 34px;
        }

        &[type="radio"] {
          & + .marker {
            &:before {
              border-radius: 50%;
            }
            &:after {
              left: 9px;
              top: 0;
              width: 10px;
              height: 10px;
              border: none;
              border-radius: 50%;
              background: $form-field-placeholder;
              opacity: 0;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
  // Opinion scale
  .freeform__column {
    .opinion-scale .opinion-scale-scales {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
      grid-gap: 0;
      list-style: none;
      & > * > label {
        display: block;
        padding: 6px 12px;
        margin: 0 0 5px;
        border: 1px solid #cccccc;
        border-left: none;
        white-space: nowrap;
        text-align: center;
        color: black !important;
        cursor: pointer;
      }
      & > * input {
        position: absolute;
        left: -9999px;
        top: -9999px;
        width: 1px;
        height: 1px;
        overflow: hidden;
        visibility: hidden;
        &:checked ~ label {
          background: #e6e6e6;
        }
      }
      & > *:first-child > label {
        border-left: 1px solid #cccccc;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      & > *:last-child > label {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
    .opinion-scale ul.opinion-scale-legends {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
      grid-gap: 0;
      li {
        text-align: center;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
      }
    }

    .opinion-scale {
      width: 100%;
    }
    .opinion-scale-scales {
      margin: 0;
    }
    .opinion-scale-scales > * > label {
      border-color: $primary-color-alt;
    }
    .opinion-scale .opinion-scale-scales > * input:checked ~ label {
      background-color: $primary-color;
      color: white !important;
    }
  }
  // Multi-/select
  select {
    display: block;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    overflow: overlay;
    &::-ms-expand {
      display: none;
    }
    // Custom stroll
    &::-webkit-scrollbar {
      width: 4px;
      height: 100%;
    }
    &::-webkit-scrollbar-button {
      width: 100%;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: $form-field-border;
      border: 0px none $form-field-background;
      border-radius: 0px;
      &:hover,
      &:active {
        background: $form-field-border;
      }
    }
    &::-webkit-scrollbar-track {
      background: $form-field-background;
      border: 2px solid $form-field-background;
      border-radius: 0px;
      &:hover,
      &:active {
        background: $form-field-background;
      }
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
    &:after {
      position: absolute;
      content: "";
      top: 14px;
      right: 25px;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-color: $form-field-border transparent transparent transparent;
    }
  }
  option {
    padding: 7px 20px;
    &:checked,
    &:focus,
    &:hover,
    &:active {
      background: $form-field-border !important;
    }
  }
}
// Form field - Errors
.ff-form-errors,
.ff-errors,
.ff-errors--custom {
  color: $form-field-error !important;
  * {
    color: $form-field-error !important;
  }
}

.custom-form-block {
  .freeform-form-has-errors {
    color: $form-field-error !important;
  }
  .freeform__errors {
    width: 100%;
    margin: 8px 0 0;
    padding: 0;
    color: $form-field-error !important;
    font-size: 14px;
    * {
      color: $form-field-error !important;
    }
    li {
      margin: 0 0 4px 21px;
      list-style: disc;
      color: $form-field-error !important;
      font-size: 14px;
    }
  }
}
// Form field - Instructions
.custom-form-block {
  .freeform__instructions,
  .freeform-row .freeform__column .freeform__instructions {
    color: $form-field-filled;
    font-size: 12px;
    line-height: 18px;
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 8px;
  }

  .freeform__instructions {
    p {
      font-size: 12px !important;
      line-height: 18px !important;
      margin: 0 !important;
    }

    a {
      text-decoration: underline;
    }
  }
}
// Form field - Submit
.custom-form-block {
  .freeform__column {
    .form__submit {
      min-height: 60px;
      text-transform: uppercase;
      cursor: pointer;
      padding: 14px 16px;
      color: white;
      background: $newsletter;
      border-radius: 2px;
      margin-bottom: 8px;
      transition: all 0.3s;
      text-decoration: none;
      text-align: center;
      margin: 0 !important;
      &:hover,
      &:focus {
        color: $text-white;
        text-decoration: none;
        background: $primary-color-darker;
      }
      &:last-of-type {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
      }

      &::after {
        content: "\f061";
        font-size: 18px;
        font-family: $font-awesome;
        color: $white;
        display: inline-block;
        transform: rotate(45deg);
      }
      @include tablet {
        font-size: 15px;
        padding: 14px 16px;
        min-height: 48px;
      }
    }
    // Prev btn
    .form__submit {
      &:not(:last-of-type) {
        width: auto;
        background: none;
        color: $primary-color;
        text-decoration: underline;
        padding: 4px 35px;
        text-align: left;
        &:hover,
        &:focus {
          background: transparent;
          color: $primary-color-darker;
        }
      }
    }
  }
}

// ------------------------------ //
// Custom Form Styling - Header   //
// ------------------------------ //
.conversion-header {
  .form__content {
    padding: 25px 25px 0 25px;
    background: white;
    box-shadow: 0 0 64px -24px rgb(0 0 0 / 20%);
    
    .custom-form-success {
      margin-bottom: 25px;
      padding: 25px;
      background: white;
      box-shadow: 0 0 64px -24px rgba(0, 0, 0, 0.2);
      font-family: $font-tertiary;
    }
  }
  
  .form__header {
    margin: -18px -18px 0;
    width: calc(100% + 2 * 18px);
  }
}
.custom-form-block {
  .form__header {
    display: block;
    position: relative;
    margin: 0;
    width: 100%;
    
    .freeform-pages,
    .form__navigation {
      margin: 0;
    }
  }

  .form__img {
    border-bottom: 7px solid white;
    display: block;
    position: relative;
    padding-top: 50%;
  }

  .form__label {
    font-size: 14px;
    position: absolute;
    right: 20px;
    top: 0;
    z-index: 1;
    transform: translateY(-50%);
    padding: 8px 16px;
    color: white;
    font-family: $font-primary;
    background: $primary-color;
    text-transform: uppercase;
  }
}

// ------------------------------ //
// Custom Form Styling - Footer   //
// ------------------------------ //
.newsletter {
  margin-top: 32px;
  color: white;

  .title {
     font-size: 16px;
     font-weight: 700;
     
     @include tablet {
      font-size: 22px;
     }
  }

  .custom-form-block {
    background: rgba(42, 46, 56, 0.1);
    box-shadow: 0 0 64px -24px rgba(0, 0, 0, 0.5);
    
    .freeform__input {
      color: white;
      background-color: #596071;
      
      &::placeholder {
        color: white;
        transition: color 0.2s ease;
        transition-delay: 0.1s;
      }
      
      &::-webkit-input-placeholder {
        color: white;
        transition: color 0.2s ease;
        transition-delay: 0.1s;
      }
     
      &:-moz-placeholder {
        color: white;
        transition: color 0.2s ease;
        transition-delay: 0.1s;
      }
      
      &::-moz-placeholder {
        color: white;
        transition: color 0.2s ease;
        transition-delay: 0.1s;
      }
     
      &:-ms-input-placeholder {
        color: white;
        transition: color 0.2s ease;
        transition-delay: 0.1s;
      }
      
      &:not(:placeholder-shown),
      
      &:focus {
        &::placeholder {
          color: $primary-color-alt;
        }
        
        &::-webkit-input-placeholder {
          color: $primary-color-alt;
        }
       
        &:-moz-placeholder {
          color: $primary-color-alt;
        }
        
        &::-moz-placeholder {
          color: $primary-color-alt;
        }
        
        &:-ms-input-placeholder {
          color: $primary-color-alt;
        }
      }
    }

    .freeform__column {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0 0 0.7em 0;
        color: white;
      }

      p,
      ol,
      ul {
        color: white;
        font-size: 16px;
        margin: 0 0 0.7em 0;
      }

      .freeform-input-only-label,
      label:nth-of-type(n + 2) {
        color: white;
      }

      .opinion-scale-scales > * > label {
        color: white !important;
        border-color: $primary-color-alt;
      }
    }
  }
}

.newsletter {
  .form__content {
    color: white;
    background-color: transparent;
    
    .custom-form-success {
      margin-bottom: 25px;
      padding: 25px;
      background: rgba(42, 46, 56, 0.1);
      box-shadow: 0 0 64px -24px rgba(0, 0, 0, 0.5);
      font-family: $font-tertiary;
    }

    .success-title,
    .intro,
    .intro *:not(a) {
      color: white;
    }
  }
}

// ------------------------------ //
// Custom Form Element - Success  //
// ------------------------------ //
.custom-form-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  margin-bottom: 25px;
  background: white;
  font-family: $font-tertiary;
  text-align: center;

  @include tablet {
    padding: 40px;
    box-shadow: 0 0 64px -24px rgba(0, 0, 0, 0.2);
  }

  .success-title,
  .intro,
  .intro *:not(a) {
    color: $text-secondary;
    padding-bottom: 1rem;
  }

  .success-pre-title {
    margin-bottom: 20px;
  }

  .success-pre-title {
    margin-top: 0;
    padding: 0;
    font-size: 14px;
    text-transform: uppercase;
    color: $primary-color-alt;
    transition: all 0.1s linear;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: -10px 0;
  }

  .intro {
    width: 100%;
    margin: 0 0 26px 0;
  }

  .success__icon {
    height: 60px;
    margin-bottom: 8px;
  }
  
  .success__buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 100%;

    @include until-tablet {
      a,
      button {
        &:first-child:last-child {
          width: 100%;
        }
      }
    }

    @include tablet {
      width: 520px;
    }
  }
}

.freeform__radio,
.freeform__checkbox {
  .text {
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

// form-summary
.form-summary {
  padding-top: 28px;

  @include tablet {
    padding-top: 0;
  }

  &__title {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 18px;

    @include tablet {
      font-size: 24px;
      line-height: 24px;
    }
  }
  &__field {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 24px;

    @include tablet {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 28px;
    }

    b {
      text-transform: uppercase;
      margin-bottom: 2px;

      @include tablet {
        margin-bottom: 6px;
      }
    }
    span {
      text-transform: capitalize;
    }
  }
}