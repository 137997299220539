@use "sass:list";

.sitemap-content {
  margin: 32px 0 0;
  grid-column: 2 / 14;
}

.sitemap {
  font-size: 14px;
}
.sitemap-section,
.sitemap-fields {
  background: #f2f2f2;
}

.sitemap-fields {
  opacity: 0.5;
  font-size: 12px;
  cursor: pointer;
}

.sitemap-fields small {
  opacity: 0.5;
}

.show-on-hover {
  display: none;
}

.sitemap-fields:hover .show-on-hover {
  display: block;
}

.sitemap-section strong {
  font-size: 16px;
}

.sitemap-section span {
  opacity: 0.3;
  font-size: 12px;
}

.sitemap-section span:last-child {
  float: right;
  font-size: 12px;
}

.sitemap-section span.icon {
  float: right;
  margin: 0 0 0 8px;
  opacity: 1;
  font-size: 18px;
}

.spacer td {
  padding: 10px 0;
  border: none;
}

.blokje {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
  grid-row: 2 / 3;
  padding: 4px 0;
  margin-bottom: 8px;
  text-align: center;
}

@for $i from 1 through 4 {
  .row--#{$i} {
    grid-row: list.slash($i + 1, $i + 2);
  }
}

.row--1 {
  @for $j from 0 through 13 {
    &.blokje--#{$j} {
      grid-column: list.slash($j + 1, $j + 2);
    }
  }
}

.row--2.blokje--0 {
  grid-column: 2 / 8;
}
.row--2.blokje--1 {
  grid-column: 8 / 14;
}

.row--3.blokje--0 {
  grid-column: 2 / 5;
}
.row--3.blokje--1 {
  grid-column: 5 / 8;
}
.row--3.blokje--2 {
  grid-column: 8 / 15;
}

.row--4.blokje--0 {
  grid-column: 2 / 5;
}
.row--4.blokje--1 {
  grid-column: 5 / 8;
}
.row--4.blokje--2 {
  grid-column: 8 / 11;
}
.row--4.blokje--3 {
  grid-column: 11 / 14;
}
