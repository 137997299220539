.search-result {
  padding: 60px 0;

  .search-result__count,
  .search-result__list {
    grid-column: 2 / span 12;
  }
}

.result {
  display: flex;
  margin: 40px 0;
  @include tablet {
    margin: 65px 0;
    @include desktop {
      margin: 80px 0;
    }
  }

  .result__image {
    flex: 1;
    min-width: 65px;
    background: $background-light;
    @include mobile-only {
      transform: translateX(-25px);
    }
    @include tablet {
      flex: 0 0 (65px * 3 + 25px * 2);
      margin-right: 25px;
    }
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      left: 0;
      top: 0;
      position: absolute;
    }
  }
  .result__content {
    flex: 8;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .result__type {
    margin: 20px 0;
    color: $text-alt;
    font-family: $font-secondary;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-transform: uppercase;
  }
  .result__title {
    margin-bottom: 14px;
    color: $text-secondary;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: -0.4px;
    line-height: 23px;
  }
  .result__description {
    color: $text-secondary;
    font-size: 18px;
    line-height: 26px;
  }
  .result__link {
    padding: 20px 0;
    height: 23px;
    width: 132px;
    color: $text-primary;
    font-family: Roboto;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
  }
}
