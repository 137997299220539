.block--author {
  .block__title {
    grid-row: 1 / 2;
    grid-column: 4 / 12;

    margin: 0 0 20px;
  }

  .author {
    grid-row: 2 / 3;
    grid-column: 4 / 12;
    display: flex;
    flex-wrap: wrap;
  }

  .image {
    margin-right: 20px;
    height: 75px;
    width: 75px;
    max-width: 75px;
    border-radius: 100%;
    @include desktop {
      height: 100px;
      width: 100px;
      max-width: 100px;
    }
  }

  .author__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .author__name {
    color: $text-dark;
  }

  .author__quote {
    border-top: 1px solid $line-color;
    margin: 24px 0 0;
    padding: 20px 0 0;
    color: $text-dark;
  }
}

.block:not(.colorscheme-dark) + .block--author .container,
.block:not(.colorscheme-dark) + .block--author .np-container {
  padding-top: 0;
}
