.trainings-overview {
  background-color: $background-light;

  &__search {
    @include desktop {
      width: 90%;
      max-width: 1440px;
      margin: 0 auto;
    }
  }
}
