.block--contact {
  @include tablet {
    .block__top-left {
      grid-row: 1 / 2;
      grid-column: 2 / 8;
    }

    .block__top-right {
      grid-row: 1 / 2;
      grid-column: 9 / 14;
    }

    .block__employees {
      grid-row: 2 / 3;
      grid-column: 2 / 14;
    }

    .employees {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: $grid-gap;
      grid-row-gap: 40px;

      margin: 80px 0 0;
    }
  }

  .contact-info {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0 0;

    @include tablet {
      margin: 40px 0 0;
      padding: 8px 0;
    }

    a {
      width: 50%;
      font-family: $font-secondary;
      text-decoration: underline;
      color: $form-field-filled;
      font-weight: 700;
      @include mobile-only {
        margin: 4px 0 0;
      }
      @include desktop {
        font-weight: 14px;
      }
    }

    .fal {
      width: 32px;
      text-align: center;
      color: $text-dark;
    }
  }
}
