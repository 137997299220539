.usps-list {
  color: $forest-green;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;

  @include widescreen {
    display: flex;
    align-items: center;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__icon {
    margin-right: 0.67em;
  }

  svg {
    width: 1.5em;

    @include tablet {
      width: 1.5em;
    }
  }
}
