.training-details-cta {
  padding-top: 3rem;
  padding-bottom: 3rem;

  background: $background-blue url($base-url+"blokjes-light.svg") no-repeat bottom left;
  background-size: auto 300px;

  color: $white;

  @include tablet {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }

  &__inner {
    grid-column: 2/14;

    @include desktop {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__content {
    max-width: 570px;
  }

  &__title {
    margin-bottom: 1rem;

    font-size: 18px;

    @include tablet {
      margin-bottom: 1.5rem;

      font-size: 34px;
    }
  }

  &__link {
    display: inline-block;

    margin: 1rem 0;

    height: 48px;
    width: initial;
    padding: 0.75rem 68px 0.75rem 1rem !important;
  }
}
