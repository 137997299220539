.training-apply-form {
    &__content{
        order: 2;
        grid-column: 2/14;
        @include desktop {
            grid-column: 2/ span 6;
            padding-top: 37px;
            padding-bottom: 37px;
            padding-right: 26px;
            order: unset;
        }
    }
    
    &__title {
        display: none;
        margin: 0;
        font-size: 28px;
        line-height: 36px;
        color: $neutrals-n-500;
        
        @include desktop {
            display: flex;
            font-size: 40px;
            line-height: 48px;
            order: unset;
        }
    }

    &__title--mobile {
        display: block;
        margin-bottom: 27px;
        order: 0;
        @include desktop {
            display: none;
        }
    }


    &__form .form__content {
        box-shadow: none;
        -webkit-box-shadow: none;
        padding: 0;
    }

    &__container .training-summary__container--mobile {
        order: 1;
        @include desktop {
            order: unset;
        }
    }

    &__container--success .training-apply-form {
        &__content {
            grid-column: 2 / 14;
            margin: 0 auto;
            
            @include desktop {
                max-width: 700px;
                grid-column: 4 /span 8;
            }
        }
        &__title {
            display: none;
        }
    }

    &__container--success .training-summary__container {
        display: none;
    }
}