.block--cta-list {
  .block__content {
    grid-column: 2 / span 3;
    padding-bottom: 25px;
  }
  .block__list {
    grid-column: 5 / span 9;
    display: block;
  }
}

.cta-list-item {
  width: 100%;
  padding-bottom: 45px;

  @include tablet {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: $grid-gap;
  }

  .title {
    grid-column: span 3;

    @include tablet {
      padding-bottom: 20px;
    }
  }

  @include mobile-only {
    .wysiwyg,
    .button--primary {
      margin-top: 16px;
    }
  }
  .cta-list-item-link {
    width: 100%;
    a {
      width: 100%;
    }
  }
}
