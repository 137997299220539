// BASE GRID LAYOUT STYLING
.container,
.np-container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  padding-left: $container-padding-mobile;
  padding-right: $container-padding-mobile;
  width: 100%;

  @include tablet {
    display: grid;
    grid-template-columns: $container-padding repeat(12, 1fr) $container-padding;
    grid-column-gap: $grid-gap;
    max-width: 100%;
    padding: 0;
  }

  @include container-min {
    grid-template-columns: 1fr repeat(12, calc($grid-width / 12) - $grid-gap) 1fr;
  }
}
