.trainings-overview-search {
  &__inner {
    display: flex;

    width: 100%;
  }

  &__filters-menu {
    position: fixed;
    top: 0;

    z-index: 10;

    width: 100%;
    height: 100vh;

    overflow-y: auto;

    background-color: $white;

    @include desktop {
      position: relative;

      margin: 0;

      flex: 0 1 0;
      height: 100%;
      min-width: 33%;
      max-width: 445px;

      overflow-y: initial;
    }
  }

  &__filters-menu-title {
    margin: 2rem 0;

    font-size: 24px;

    @include desktop {
      display: none;
    }
  }

  &__filters-menu-actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    position: fixed;
    bottom: 0;

    z-index: 10;

    padding: 1rem;
    margin-left: -1rem;
    width: 100%;

    background-color: $white;
    box-shadow: 2px 2px 6px 6px #00000010;

    @include desktop {
      display: none;
    }
  }

  &__filters {
    padding: 1rem 1rem 6.5rem;

    @include desktop {
      display: block;

      padding: 3rem 1rem;

      border: 1px solid $forest-green-light;
      border-radius: 2px;
    }
  }

  &__popular-trainings {
    display: flex;
    flex-direction: column;

    padding: 0 1rem;

    @include desktop {
      padding: 0;
    }

    .usps-list {
      color: $forest-green-alt;
    }
  }

  &__button-popular-training {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;

    padding: 1rem;
    margin: 1rem 0;

    background-color: $forest-green;
    border: 1px solid $forest-green-light;
    border-radius: 2px;

    color: $white;
    font-family: $font-secondary;
    font-size: 18px;
    text-transform: uppercase;

    transition: 0.2s;

    &:hover,
    &:focus {
      background-color: $secondary-color;
    }

    @include desktop {
      padding: 1.5rem;
      margin: 1rem 3%;

      font-size: 24px;
    }
  }

  &__popular-training-label {
    &--mobile {
      display: inline-block;
    }

    &--desktop {
      display: none;
    }

    @include desktop {
      &--mobile {
        display: none;
      }

      &--desktop {
        display: inline-block;
      }
    }
  }

  .popular-training-icon {
    &--is-open {
      transform: rotate(180deg);
    }
  }

  &__list {
    list-style-type: none;

    padding: 0 1rem;

    @include desktop {
      padding: 0 3%;
    }
  }

  &__card {
    display: block;
    padding: 1rem;
    margin-bottom: 1rem;

    position: relative;

    background-color: $white;
    border: 1px solid $forest-green-light;
    border-radius: 2px;

    color: $secondary-color;

    &:hover {
      text-decoration: none;
      color: $secondary-color;

      .trainings-overview-search__card-link {
        &::after {
          transform: rotate(0deg);
        }
      }
    }

    @include desktop {
      padding: 2.5rem 2.5rem 1rem;
    }

    .price-indicator__price {
      margin: 1rem 0;

      @include desktop {
        span {
          margin: 0.5rem 0;
        }
      }
    }

    &--is-Dark {
      background-color: $forest-green;

      color: $white;

      &:hover {
        text-decoration: none;
        color: $white;
      }

      .trainings-overview-search__card-link {
        background-color: $white;

        color: $secondary-color;

        &::before {
          background-color: $white;
        }
      }

      .trainings-overview-search__card-attribute-list-item {
        color: $forest-green-alt;

        border-color: $forest-green-alt;

        &--filled {
          background-color: $forest-green-alt;

          color: $forest-green;
        }
      }

      .price-indicator__price {
        span:nth-child(1),
        span:nth-child(2) {
          color: $white;
        }
      }
      .price-indicator__btw{
        color: $forest-green-alt;
      }
    }
  }

  &__card-header {
    display: flex;
    justify-content: space-between;
  }

  &__card-discount-label {
    padding: 1rem;
    margin-left: 1rem;
    margin-top: -1rem;
    margin-right: -1rem;
    height: 55px;

    background-color: $forest-green-light;
    border-radius: 2px;

    color: $text-secondary;
    white-space: nowrap;

    @include desktop {
      margin-top: -2.5rem;
      margin-right: -2.5rem;
    }
  }

  &__card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__card-body-left-column {
    flex-basis: 100%;
    @include desktop{
      flex-basis: 64%;
    }
  }

  &__card-body-right-column {
    display: none;
    margin: 1rem 0;

    @include desktop {
      display: block;
    }

    .price-indicator__price {
      display: block;
      margin: 0;
    }
  }

  &__card-title {
    margin: 1rem 0;
    font-size: 28px; //https: //egotribe.atlassian.net/browse/GS-621
  }

  &__card-description {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    width: 100%;
    margin: 1rem 0;
    
    @include tablet {
      width: 100%;
      max-width: 525px;
      -webkit-line-clamp: 3;
    }
    @include desktop {
      -webkit-line-clamp: 2;
    }
  }

  &__card-usps {
    display: none;

    @include desktop {
      display: block;

      .usps-list {
        display: flex;

        margin-top: 0;
      }

      .usps-list__icon {
        display: none;
      }

      .usps-list__item {
        &:not(:last-of-type) {
          &::after {
            content: "•";
            margin: 0.25rem;
          }
        }
      }
    }

    @include widescreen {
      .usps-list__icon {
        display: block;
      }
    }
  }

  &__card-footer {
    display: block;

    @include desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__card-footer-bottom {
    @include tablet {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include desktop {
      display: block;
    }

    .price-indicator__price,
    .price-indicator__btw {
      display: block;

      @include desktop {
        display: none;
        margin: 0;
      }
    }
  }

  &__card-attribute-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin: 1rem 0;
    list-style: none;
  }

  &__card-attribute-list-item {
    margin: 0;
    padding: 0.5rem;
    border: 1px solid $forest-green;
    border-radius: 2px;
    font-size: 15px;
    font-family: $font-secondary;
    text-transform: uppercase;
    color: $forest-green;

    &--filled {
      background-color: $forest-green;

      color: $white;
    }
    span {
      white-space: nowrap;
    }
  }

  &__card-link {
    width: 100%;
    display: inline-block;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 1rem;
    border-radius: 2px;
    margin-top: 0.5rem;

    @media (min-width: 420px) {
      width: initial;
    }
  }

  &__form-label {
    display: block;
    margin: 1.5rem 0 0.5rem;

    font-family: $font-secondary;
    font-weight: bold;
  }

  &__form-field-divider {
    display: block;

    height: 15px;
    width: 100%;
    margin: 10px 0;

    background-image: radial-gradient($forest-green-light 40%, transparent 60%);
    background-size: 5px 5px;
    background-position: 0 0;

    opacity: 0.6;
  }

  &__range-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    
    & > div{
      white-space: nowrap;
    }

    .vue-slider {
      flex-basis: 80%;
    }
  }

  &__results {
    width: 100%;
    flex-grow: 1;
  }

  &__no-results {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 1rem;

    text-align: center;

    @include desktop {
      margin: 2rem;
    }
  }

  &__no-results-label {
    width: 100%;
    margin: 1rem;
  }

  &__no-results-icon {
    width: 75px;
  }

  &__results-header {
    padding: 1rem;

    background-color: $white;
    border-bottom: 1px solid $forest-green-light;

    @include desktop {
      padding: 0;
      margin: 2.5rem 3% 0;

      background-color: transparent;
    }
  }

  &__results-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include desktop {
      display: block;
    }
  }

  &__results-menu-filter-toggle {
    padding: 0.5rem;

    background-color: transparent;

    &--mobile {
      padding: 1rem;
      width: 100%;
      background-color: $primary-color;
      border-radius: 2px;
      border: 1px solid $primary-color;

      font-size: 14px;
      color: $white;
      font-family: $font-secondary;
      text-transform: uppercase;
      text-decoration: none;

      svg{
        margin-left: 0.5rem;
      }
    }
    

    &--close {
      display: flex;
      align-items: center;

      position: absolute;
      top: 2.5rem;
      right: 1rem;

      font-weight: bold;

      i {
        margin-left: 1rem;

        font-size: 1rem;
      }
    }

    @include desktop {
      display: none;
    }
  }

  &__results-counter {
    display: none;

    @include desktop {
      display: block;

      margin: 1rem 0;

      font-weight: bold;
    }
  }

  &__results-sort-and-clear {
    @include mobile-only {
      .ais-ClearRefinements {
        display: none;
      }
    }

    @include desktop {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__sort {
    position: relative;

    .fas {
      position: absolute;
      top: 0.9rem;
      right: 0;

      font-size: 12px;

      pointer-events: none;
    }
  }

  &__select {
    position: relative;

    svg, .fas {
      position: absolute;
      right: 1rem;
      top: 0.9rem;
      font-size: 12px;
      pointer-events: none;
    }
  }

  // Algolia overrides

  .ais-Hits-item {
    background: none;
    box-shadow: none;
  }

  .ais-SortBy-select {
    padding-right: 1.5rem;
    background: none;
    border: none;
    box-shadow: none;
  }

  .ais-RefinementList-checkbox {
    width: 1rem;
  }

  .ais-SortBy-select,
  .ais-MenuSelect-select {
    // text-transform: uppercase;
    background-image: none;
    background-color: $background-light;
    border: 1px solid  $primary-color-alt;
    box-shadow: none;
  }

  .ais-ClearRefinements {
    &--mobile {
      flex: 1 0 0;
      margin-right: 1rem;

      .ais-ClearRefinements-button {
        display: inline-block;

        padding: 0;
        height: 50px;

        border: 1px solid $forest-green;
        border-radius: 2px;

        color: $forest-green;
        font-size: 14px;
        font-family: $font-secondary;
        text-transform: uppercase;
        text-decoration: none;

        &--disabled {
          opacity: 0.5;
        }
      }
    }
  }

  .ais-SearchBox-input {
    background-image: none;
    background-color: $background-light;
    border-color: $primary-color-alt;
    box-shadow: none;

    color: $text-dark;

    &::placeholder {
      color: $text-dark;
      opacity: 0.6;
    }
  }

  .ais-ClearRefinements-button {
    padding: 0;

    border: none;
    background: none;
    box-shadow: none;

    text-decoration: underline;

    &:hover,
    &:focus {
      color: $primary-color;
    }

    &--disabled {
      display: none;
    }
  }
}

@include mobile-only {
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.7s cubic-bezier(0, 0.145, 0, 1.02);
  }

  .slide-fade-enter-active {
    transform: translateY(-100%) scale(0.9);
  }

  .slide-fade-enter-to {
    transform: translateY(0%) scale(1);
  }

  .slide-fade-leave-active {
    transform: translateY(0%);
  }

  .slide-fade-leave-to {
    transform: translateY(-100%);
  }
}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox, 
.ais-GeoSearch-input:checked {
  color: #2F8ED2;
  background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27white%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e") !important;
}

