.price {
    display: flex;
    flex-direction: column;
    
    &__old-value{
        font-family: $font-secondary;
        font-size: 16px;
        font-weight: 400;
        text-decoration: line-through;
        color: $forest-green;
    }
    
    &__value {
        font-family: $font-secondary;
        font-size: 32px;
        font-weight: 700;
    }
    
    &__btw {
        font-family: $font-secondary;
        font-size: 16px;
        font-weight: 700;
        color: $forest-green;
        text-transform: uppercase;
        margin-top: 24px;
    }

    &--compact .price{
        &__value { 
            font-size: 18px;
            @include desktop {
                font-size: 20px;
            }
        }
        &__btw {
            margin-top: 0;
        }
    }
}