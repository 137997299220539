.instructions-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  padding: 32px 0;
  height: 100%;
  width: 100%;
  z-index: 99;

  h3 {
    margin-bottom: 24px;
  }

  p {
    color: $form-newsletter;
    font-size: 16px;
  }

  .button--primary {
    margin-top: 32px;
    padding: 0 20px;
    width: 100%;

    &:after,
    &:before {
      display: none;
    }
  }

  &__image {
    width: 100%;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &__content {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    width: calc(100vw - 40px);
    max-width: 375px;
    background: #fff;
  }

  &__text {
    text-align: center;
    padding: 32px 24px;
  }
}
