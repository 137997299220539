// Grid styling
.block__navigation {
  grid-column: 2 / span 12;
}
.block__list {
  grid-column: 2 / span 12;
}

// navigation styling
.article-navigation {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  @include tablet {
    margin-bottom: 96px;
  }

  .article-navigation__title {
    padding-right: 25px;
    height: max-content;
    margin-bottom: 0;
    margin-top: 4px;
  }
  .article-navigation__list {
    list-style-type: none;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    @include tablet {
      margin-top: 0;
      justify-content: flex-end;
    }
    li {
      font-size: 15px;
      margin: 0;
      padding: 0;
      .selected {
        text-decoration: none;
        color: $text-dark;
      }
      @include tablet {
        padding: 0 8px;
        margin: 0 0 8px 12px;
      }
      a {
        margin: 0;
        border: 1px solid #2f8ed2;
        color: #2f8ed2;
        background: transparent;
        margin: 4px;
        padding: 4px 8px;
        display: block;
        height: auto;
        &.selected {
          background: #2f8ed2;
          color: white;
          @include tablet {
            background: none;
            color: #2a2a2a;
          }
        }
        @include tablet {
          color: #2f8ed2;
          background: none;
          border: none;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  @include tablet {
    align-self: center;
  }
  @include mobile-only {
    flex-direction: column;
  }
}

// overview list styling
.block__list {
  @include tablet {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: inherit;
  }
  .no-articles-found {
    grid-column: 2 / span 12;
    text-align: center;
  }
  .pagination {
    grid-column: span 12;
    margin-top: 32px;
    @include tablet {
      padding: 0 65px;
    }
  }
}
