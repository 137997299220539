.block--work-at-gitp {
  & > .np-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.work-at {
  padding: 1.5rem;
  background-color: $background-dark;
  background: $background-dark url($base-url+"blokjes-light.svg") no-repeat bottom left;
  background-size: auto 600px;
  background-position: -330px 20px;
  color: $white;
  text-align: center;
  width: 100%;
  grid-column: 1/-1;

  &.np-container {
    padding: 1.5rem !important;
  }

  img, 
  .image {
    display: none;
  }

  @include desktop {
    text-align: left;
    background-position: -150px 100px;

    img, 
    .image {
      display: block;

      height: 400px;
      width: 370px;
    }
  }

  &__inner {
    grid-column: 2 / 14;

    @include desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 1.5rem 0;
    }
  }

  &__content {
    max-width: 525px;
  }

  &__title {
    margin: 1rem 0;

    font-family: $font-secondary;
    font-size: 18px;
  }

  &__text {
    margin: 1rem auto;
    max-width: 525px;

    @include desktop {
      margin: 1rem 0;
    }
  }
  

  &__cta {
    margin: 1rem auto 0.5rem;

    @include desktop {
      margin: 1rem 0;
    }
  }
}
