.price-indicator {
  display: flex;
  align-items: center;

  &__subtext {
    display: inline;
    font-size: 1.5rem;
    bottom: 0;
  }

  &__price {
    display: block;
    align-items: center;

    margin: 1.5rem 0;

    font-family: $font-secondary;
  }

  &__base-price,
  &__discount {
    display: inline-block;
    font-size: 32px;
    font-weight: 700;
  }

  &__discount {
    display: inline;
    margin-right: 1rem;
  }

  &__base-price {
    display: block;
  }

  &__discount + &__base-price {
    font-size: 16px;
    font-weight: 400;
    text-decoration: line-through;
    color: $forest-green;
  }

  &__btw {
    font-family: $font-secondary;
    font-size: 16px;
    font-weight: 700;
    color: $forest-green;
    text-transform: uppercase;
  }
}
