// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

table {
  border-collapse: collapse;
}

th,
td {
  padding: 4px 8px;
  border: 1px solid #f2f2f2;
}

th {
  background: #ddd;
  text-align: left;
}
