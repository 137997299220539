.training-details-header {
  position: relative;

  @include desktop {
    padding: 5rem 1rem 1rem;
  }

  &--with-background-image {
    color: $white;

    img,
    picture {
      object-fit: cover;
    }

    .training-details-header__breadcrumb {
      .is-active {
        color: $white;
      }
    }

    .training-details-header__title {
      max-width: initial;
      font-size: 28px;
      line-height: 36px;
      color: $neutrals-n-500;

      @include desktop {
        font-size: 40px;
        line-height: 48px;
      }
    }

    &__breadcrumb {
      li.is-disabled {
        color: $white;
      }

      .is-active {
        color: $white;
      }
    }
  }

  @include tablet {
    padding: 5rem 0 2rem;

    background-color: $background-light;
  }

  &__breadcrumb {
    .breadcrumb {
      margin: 1.5rem 0;
      padding: 0;

      li.is-disabled {
        color: $secondary-color;
      }
    }

    .is-active {
      color: $text-secondary;

      .item--link {
        text-decoration: none;
      }
    }
  }

  &__inner {
    @include tablet {
      grid-column: 2/14;
    }
    @include desktop {
      min-height: 250px;
    }
  }

  &__inner-form {
    @include tablet {
      grid-column: 2/14;
    }
  }
  &__description-form{
    @include tablet{
      max-width: 520px;
    }
  }

  &__header-content-form{
    gap: 40px;
    @include desktop{
      gap: 80px;
    }
  }
  &__header-content {
    @include tablet {
      // display: flex;
    }
  }

  &__header-content-form {
    @include tablet {
       display: flex;
    }
  }

  &__header-group {
    margin-top: 2.5rem;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: -1;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-color: #000;
      opacity: 0.2;
    }

    img,
    .image {
      width: 100%;
      height: 100%;
    }
  }

  &__subtitle {
    display: block;

    margin: 1rem 0;

    position: relative;

    font-size: 12px;
    font-weight: bold;
    font-size: 16px;
    text-transform: none;

    @include tablet {
      font-size: 18px;
    }
  }

  &__description {
    max-width: 570px;
    margin: 1rem 0;

    @include tablet {
      margin: 1rem 0;
    }
  }

  &__description-form {
    max-width: 570px;
    margin: 1rem 0;

    @include tablet {
      margin: 0;
    }
  }

  &__social-links {
    display: none;

    @include tablet {
      display: block;
      margin: 1rem 0;
    }

    a {
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      margin-right: 0.5rem;

      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      line-height: 30px;
      text-decoration: none;
    }

    a:after {
      display: block;
      top: 0;
      right: 0;
      height: 100%;
      width: max-content;

      color: $primary-color;
      font-family: $font-awesome;
      font-size: 24px;
    }

    .logo-- {
      &facebook:after {
        font-family: "Font Awesome 5 Brands";
        content: "\f082";
      }
      &twitter:after {
        font-family: "Font Awesome 5 Brands";
        content: "\e055";
      }
      &instagram:after {
        font-family: "Font Awesome 5 Brands";
        content: "\f081";
      }
      &linkedin:after {
        font-family: "Font Awesome 5 Brands";
        content: "\f08c";
      }
      &youtube:after {
        font-family: "Font Awesome 5 Brands";
        content: "\f431";
      }
      &website:after {
        content: "\f360";
      }
    }
  }

  &__social-links-list-item {
    display: inline-block;
  }

  &__usps {
    display: none;

    @include desktop {
      display: block;
      max-width: 50%;
    }
  }
}

.training-form-title {
  @include tablet {
    min-width: 240px;
  }
}