.container--share {
  position: sticky;
  top: calc(50vh - 50px);
  transform: translateY(50px);
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  height: 0;

  @include tablet {
    top: calc(50vh - 100px);
    transform: translateY(100px);
    margin: 0 auto;
    max-width: $grid-width;
    width: 100%;
  }

  .share-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 21px;
    padding: 4px 8px;
    height: max-content;
    width: max-content;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #fff;

    @include mobile-only {
      margin-right: 1px;
    }

    @include container-min {
      margin-right: 1px;
    }

    .share-buttons__title {
      line-height: 24px;
      color: $text-primary;
      margin-bottom: 12px;
      text-transform: uppercase;
      font-family: $font-secondary;
      font-size: 14px;
    }
    .share-buttons__button {
      background: none;
      text-emphasis: none;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &:after {
        color: $text-secondary;
        font-size: 38px;
      }
      &.share-buttons__button--facebook:after {
        font-family: "Font Awesome 5 Brands";
        content: "\f082";
      }
      &.share-buttons__button--linkedIn:after {
        font-family: "Font Awesome 5 Brands";
        content: "\f08c";
      }
    }
  }
}
