$image-overlay-distance: 250;

.article-header {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 40px;

  @include mobile-only {
    &.light-background {
      background: $background-light;
    }
  }

  @include tablet {
    padding-top: 90px;
  }

  &.has-image {
    .article-header__content {
      margin: 0 0 30px;
    }
  }

  .article-header__content {
    display: flex;
    flex-direction: column;
    margin: 0 0 20px;

    @include tablet {
      margin: 80px auto 40px;
      grid-column: 4 / 12;
      grid-row: 3 / 4;
    }
    h1 {
      display: block;
    }
    .post-date {
      margin: 16px 0 0;
      color: $text-alt;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      font-family: $font-secondary;
    }
    .article-tag-list {
      margin: 16px 0 0;
      display: inline-block;
      .article-tag {
        display: inline-block;
        list-style: none;
        margin: 0 0 8px;
        color: $text-alt;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        font-family: $font-secondary;
      }
    }
  }

  .breadcrumb {
    margin: 72px 0 32px;
    grid-column: 2 / 14;
    grid-row: 1 / 2;

    @include tablet {
      margin: 90px 0 0;
    }
  }

  h1 {
    margin: 0 0 16px;
  }

  .header-image {
    @include dots-background;


    position: relative;
    display: flex;
    margin-top: 32px;
    padding: 56.25% 0 0;
    width: 100%;

    @include tablet {
      grid-column: 2 / 14;
      grid-row: 2 / 3;

      &:before {
        content: "";
        position: absolute;
        z-index: 2;
        height: 100px;
        max-width: #{($article-width + $grid-width) * 0.5};
        width: 100%;
        background: $background-light;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }

    @include desktop {
      padding-top: 0;
      height: 450px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      max-height: 100%;
      width: 100%;
      object-fit: cover;
    }  
  }

  &.has-image {
    .header-image  + .article-header__content {
      z-index: 2;
      margin-top: 20px;

      @include tablet {
        margin-top: -80px;
      }

      @include desktop {
        margin-top: -86px;
      }
    }
  }
}
