.conversion-header {
  min-height: 60vh;

  .container,
  .np-container {
    align-items: flex-start;
    justify-content: flex-end;
  }

  .header-submenu {
    grid-column: 2 / 14;
    grid-row: 1 / 2;
    margin: 90px 0 0;
  }

  .breadcrumb {
    margin: 72px 0 32px;
    grid-column: 2 / 14;
    grid-row: 1 / 2;
    color: $text-dark;
    li {
      &.is-active a {
        color: white;
      }
    }
    @include tablet {
      margin: 90px 0 0;
    }
  }

  .title-wrapper {
    margin: auto 0 -6px;
    font-size: 32px;

    @include tablet {
      grid-column: 3 / 8;
      grid-row: 2 / 3;
      align-self: self-end;
      margin: 0 0 -12px;
      font-size: 56px;
    }

    .mid-title {
      white-space: normal;
    }

    h1 {
      font-size: 100%;
      line-height: 100%;
      word-break: normal;
    }
  }

  .header-form {
    @include mobile-only {
      margin: 32px 0 0;
    }
    @include tablet {
      grid-column: 9 / 14;
      grid-row: 2 / 3;
      align-self: flex-end;
      margin-bottom: -245px;
    }
    .custom-form-success {
      padding: 0 25px;
      *:not(a) {
        color: $text-secondary;
      }
    }
  }
}
