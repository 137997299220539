.video-columns {
  grid-column: 2 / 14;
}

.video-columns__title {
  margin-bottom: 40px;
}

.video-columns__sub-title {
  margin: 2rem 0 1rem;

  font-size: 14px;
  color: $primary-color;
}

.video-columns__videos-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  width: 100%;

  @include tablet {
    grid-template-columns: 1fr 1fr;
  }

  @include desktop {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include widescreen {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.video-columns__video-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}



.video-columns__video {
  position: relative;
  &-layer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
  }

  figure {
    position: relative;
    padding-top: 56.25%;
    aspect-ratio: 16 / 9;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;

    // Add !important so iframe doesn't come up with its own width & height and overrules our beautiful styling
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    aspect-ratio: 16 / 9;
  }
}

.video-columns__video-title {
  margin-bottom: 0.5rem;
}

.video-modal__trigger{
  background: none;
}
