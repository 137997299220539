.homepage-header {
  padding: 80vh 0 0;
  @include mobile-only {
    min-height: 487px;
  }
  @include tablet {
    padding: 80vh 0 0;
  }

  .image img {
    object-fit: cover
  }
}

.homepage-header__content {
  @include flex-center;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  width: 100%;
  padding: 50px 20px 30px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  justify-content: center;

  @include tablet {
    justify-content: center;
  }

  h1 {
    font-size: 28px;

    @include tablet {
      padding-top: 140px;
    }
  }

  p {
    @include mobile-only {
      display: none;
    }
  }

  @include tablet {
    padding: 0 0 100px;
  }
}
