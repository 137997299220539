// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}
*:focus {
  outline-color: $primary-color;
}

body {
  &.menu-open {
    overflow: hidden;
  }
}

/**
 * Basic styles for links
 */
a {
  color: $primary-color;
  text-decoration: none;

  @include on-event {
    color: $text-dark;
    text-decoration: underline;
  }
}

//Temporary dev-styling
.block {
  position: relative;
}

.block--logo {
  img, .image {
    width: 100%;
    max-width: 200px;
  }
}

.dev-label {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  padding: 4px 8px;
  background: $background-dark;
  color: #fff;
}

//dots background
.dots {
  background-image: radial-gradient(#fff 20%, transparent 20%);
  background-position: 0 0;
  background-size: 4px 3px;
}

.pre-title {
  position: relative;
  margin: 0 0 16px;
  font-size: 12px;
  color: $primary-color;
  line-height: 112%;
  text-transform: uppercase;
  font-family: "Ropa Sans", sans-serif;
  font-weight: 700;
  @include tablet {
    margin: 0 0 24px;
    font-size: 14px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 1px;
    left: -26px;
    height: 7px;
    width: 7px;
    background: $primary-color;

    @include tablet {
      top: 1px;
      left: -24px;
      height: 12px;
      width: 12px;
    }
  }
}

picture.image {
  display: flex;
  width: 100%;
  height: 100%;
  line-height: 0;
  background: transparent;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &--mode-fit img{
    object-fit: contain;
  }
}

picture.transparent {
  background: none;
}


.grecaptcha-badge{
  bottom: 108px !important;
}