.block--logos {
  .block__content {
    grid-column: 2 / span 12;
    margin-bottom: 32px;
  }
  .block__cases {
    grid-column: 2 / span 12;
  }

  @include mobile-only {
    .container,
    .np-container {
      padding-bottom: 0;
    }
  }
}

.logo-cases {
  .logos {
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: $grid-gap;
  }

  .logo {
    grid-column: span 6;
    @include tablet {
      grid-column: span 3;
    }
    position: relative;
    padding-bottom: 50%;
    cursor: pointer;
    background: none;
    transition: box-shadow 0.2s;
    box-shadow: 0 0 32px -32px rgba(0, 0, 0, 0);
    &.is-link,
    &.is-btn {
      &:hover {
        box-shadow: 0 0 32px -16px rgba(0, 0, 0, 0.6);
        img {
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
    }
    img {
      transition: transform 0.2s;
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 50%;
      
      max-width: 133px;
      max-height: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
    }
    &.no-link {
      cursor: default;
    }
  }

  .case {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 (-$container-padding-mobile);

    @include tablet {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: $grid-gap;
      margin: 0 (-$grid-gap*0.5);
    }
  }

  .case__image {
    grid-column: 1 / 13;
    grid-row: 1 / 4;

    .image-holder {
      width: 100%;
      position: relative;
      padding-bottom: 50%;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
      &:not(.showing) {
        display: none;
      }
    }
  }

  .case__content {
    position: relative;
    z-index: 1;
    padding: 48px $container-padding-mobile 32px;
    width: 100%;
    background: $white;

    @include tablet {
      grid-column: 7 / 13;
      grid-row: 2 / 5;
      padding: 48px 80px 0;
    }
  }

  .content__logo {
    margin: 0 0 32px;
    height: 25px;
    width: auto;

    .content__logo__holder {
      width: 100%;
      height: 100%;
      position: relative;
      img {
        position: absolute;
        height: 100%;
        width: auto;
      }
      &:not(.showing) {
        display: none;
      }
    }
  }

  .content__qoute {
    text-transform: uppercase;
    margin-bottom: 32px;
    font-size: 22px;
    font-weight: bold;
    line-height: 24px;

    @include tablet {
      line-height: 32px;
      font-size: 34px;
    }
  }

  .content__sender {
    font-family: $font-secondary;
    font-size: 14px;
    font-weight: bold;
    color: $text-primary;
  }

  .case-link {
    position: absolute;
    right: 0;
    bottom: 0;

    &:before {
      content: "";
      height: 0;
      width: 0;
      border: (92px*0.5) solid transparent;
      border-bottom-color: $primary-color;
      border-right-color: $primary-color;
      position: absolute;
      right: 100%;
      bottom: 0;
    }

    &:after {
      content: "\f061";
      font-size: 18px;
      font-family: $font-awesome;
      color: $white;
      position: absolute;
      top: -(92px * 0.4);
      left: -(92px * 0.4) + 4px;
      transform: rotate(45deg);
    }

    span {
      display: none;
    }
  }
}
