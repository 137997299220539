// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: "Open Sans", "Helvetica Neue Light", "Helvetica Neue",
  "Helvetica", "Arial", sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
  "Monaco", monospace !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: "/web/assets/" !default;

$grid-width: 1080px;
$grid-gap: 25px;
$article-width: 708px;
$container-padding: 10px;
$container-padding-mobile: 16px;

$soft-shadow: rgba($primary-color-alt, 0.3) 0 0 25px;
$regular-shadow: rgba($primary-color-alt, 0.5) 0 0 25px;
$shadow-invisible: rgba($primary-color-alt, 0) 0 0 25px;
