.simple-header {
  min-height: 60vh;
  .container,
  .np-container {
    padding-bottom: 32px;
    @include tablet {
      padding-bottom: 72px;
    }
  }

  &.title-only {
    .container,
    .np-container {
      padding-bottom: 0;
    }

    .simple-header__title {
      margin: auto 0 -6px;
      @include tablet {
        margin: auto 0 -12px;
      }
    }
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  h1 {
    font-size: 32px;

    @include tablet {
      margin: 0 0 -2px;
      font-size: 56px;
    }
  }

  p {
    @include tablet {
      font-size: 15px;
    }
  }

  .simple-header__title {
    grid-column: 2 / 8;
    grid-row: 2 / 3;
    align-items: flex-end;
    margin-top: auto;
    
    &.no-text {
      margin: auto 0 -6px;
      @include tablet {
        margin: 0 0 -12px;
      }
    }
  }

  .simple-header__text {
    grid-column: 8 / 14;
    grid-row: 2 / 3;
    margin-top: auto;
    align-items: flex-end;
  }

  .header-submenu {
    margin: 0 0 16px;

    @include tablet {
      margin: 0 0 0 auto;
    }
  }
}
