// Custom article-block width for article pages
.article-blocks {
  .block {
    .container,
    .np-container {
      padding-top: 25px;
      padding-bottom: 25px;

      @include tablet {
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }

    &.block--text-image {
      &.align-left {
        &.image-width-50,
        &.image-width-33,
        &.image-width-25 {
          @include textImage(2, 8);
        }
        @include desktop {
          &.image-width-33 {
            @include textImage(2, 10);
          }
          &.image-width-25 {
            @include textImage(2, 12);
          }
        }
        .block__content {
          grid-column-start: 2 !important;
        }
        .block__image {
          grid-column-end: 14 !important;
        }
      }

      &.align-right {
        &.image-width-50,
        &.image-width-33,
        &.image-width-25 {
          @include textImage(9, 1);
        }
        @include desktop {
          &.image-width-33 {
            @include textImage(7, 1);
          }
          &.image-width-25 {
            @include textImage(5, 1);
          }
        }
        .block__image {
          grid-column-start: 2 !important;
        }
        .block__content {
          grid-column-end: 14 !important;
        }
      }
    }
  }
}
