.training-details-reviews {
  &__inner {
    grid-column: 2/14;

    padding: 4rem 0;

    @include desktop {
      padding: 5rem 0;
    }
  }

  &__review {
    padding: 2rem;
    margin: 1rem 0;

    background-color: $background-light;
  }

  &__title,
  &__text {
    @include desktop {
      margin: 0 auto;
      text-align: center;
    }
  }

  &__title {
    max-width: 700px;
    margin-bottom: 1rem;

    font-family: $font-secondary;
    font-size: 16px;
    color: $background-blue;

    @include desktop {
      font-size: 24px;
    }

    &::before {
      content: "";
      display: block;

      width: 40px;
      height: 40px;

      margin-bottom: 1rem;
      margin-left: -7.5px;

      background-image: url($base-url+"icons/quote.svg");

      @include desktop {
        margin: 0 auto;
        margin-bottom: 0.5rem;
      }
    }
  }

  &__text {
    font-size: 16px;
    max-width: 525px;
  }
}
