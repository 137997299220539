@import "../../tailwind/abstracts/colors.scss";

.question {
  cursor: pointer;
  padding: 16px 0;
  border-bottom: 1px solid $blue-muted-bm-400;

  &:first-child {
    border-top: 1px solid $blue-muted-bm-400;
  }

  &:hover {
    .question__button {
      color: $primary-color;
    }
  }

  &.question--open {
    .question__answer {
      height: max-content;
      position: unset;
      display: block;
      .wysiwyg {
        position: unset;
      }
    }
  }

  .icon {
    transition: transform 200ms;

    &.rotate {
      transform: rotate(180deg);
    }
  }
}

.question__answer {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  .wysiwyg {
    position: absolute;
    width: 100%;
    height: max-content;
    left: 0;
    top: 0;
    padding-top: 16px;

    figure {
      img {
        width: initial;
        max-width: 100%;
      }

      @include tablet {
        justify-content: center;

        background-color: $background-light;
      }
    }
  }
}

.question__button {
  width: 100%;
  min-height: 24px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $blue-b-400;
  position: relative;
  background: none;
  line-height: 21px;
  font-size: 18px;
  outline: none;

  @include tablet {
    line-height: 24px;
    font-size: 18px;
  }
}
