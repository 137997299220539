.service-header {
  display: flex;
  flex-direction: column;

  &.light-background {
    background: $background-light url($base-url+"blokjes.svg") no-repeat bottom
      left;
    background-size: auto 300px;
  }

  @include tablet {
    margin-bottom: 60px;
  }

  h1 {
    font-size: 32px;

    @include tablet {
      font-size: 50px;
    }
  }

  p,
  .wysiwyg {
    ul,
    ol {
      margin: 12px 0 0 27px;
    }

    li {
      margin: 0 0 8px;

      @include tablet {
        margin: 0 0 4px;
      }
    }

    @include tablet {
      font-size: 15px;
    }

    .block-list__outer li:before {
      font-size: 32px;
      left: -30px;
    }
  }

  .button--primary {
    margin-top: 40px;
  }
}

.service-header__title {
  grid-column: 2 / 7;
  grid-row: 2 / 3;
  margin: 32px 0 0;

  @include tablet {
    margin: 90px 0 72px;
  }
}

.service-header__content {
  grid-column: 8 / 14;
  grid-row: 2 / 3;
  margin: 16px 0 0;

  @include tablet {
    margin: 90px 0 72px;
  }
}

.service-header__image {
  position: relative;
  grid-column: 2 / 16;
  grid-row: 3 / 4;
  margin: 32px -40px 0;
  max-height: 200px;
  line-height: 0;

  @include tablet {
    max-height: 500px;
    margin: 0 0 -60px;
  }

  img {
    height: inherit;
    max-height: inherit;
    width: 100%;
    object-fit: cover;
  }

  @include dots-background;
}
