.block--subject-menu {
  & > .np-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.subjects-navigation {
  width: 100%;
  grid-column: 1/-1;

  &__list {
    @include desktop {
      display: flex;
      justify-content: space-evenly;
    }
  }

  &__list-item {
    position: relative;
    flex: 1 0 0;
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;

    z-index: 1;

    font-size: 24px;
    font-family: $font-secondary;
    color: $white;
    text-align: center;
    white-space: nowrap;

    transform: translate(-50%, -50%);
    pointer-events: none;

    max-width: 90%;
    text-wrap: wrap;
    white-space: normal;
    word-break: normal;
    margin: 0;

    @include desktop{
      text-wrap: nowrap;
      white-space: nowrap;
    }

  }

  &__list {
    list-style-type: none;
  }

  &__image {
    position: relative;
    z-index: 0;

    height: 310px;

    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-color: #00000050;

      pointer-events: none;
    }

    .image, img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease-out;
      will-change: transform;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &__link {
    &:hover, &:focus {
      .image, img {
        transform: scale(1.1);
      }
    }
  }
}
