.block__insight-overview-header,
.block__news-overview-header,
.block__case-overview-header,
.block__employee-overview-header,
.block__vacancy-overview-header,
.block__webinar-overview {
  .title {
    margin-bottom: 32px;
    grid-column: 2 / span 12;
  }
  .wysiwyg {
    grid-column: 2 / span 12;
  }

  .block__employee-overview {
    margin: 80px 0 0;
    @include tablet {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: $grid-gap;
      grid-row-gap: 40px;
    }
  }

  .block__list {
    .overview-list-item {
      @include tablet {
        margin: 0 12px 12px;
      }
      @include desktop {
        margin: 0 (65px - 24px) (65px - 24px);
      }
      grid-column: span 6;
      a {
        text-decoration: none;
      }
    }
    .employee-list-item {
      @include tablet {
        margin: 0 12px 12px;
      }
      @include desktop {
        margin: 0 (65px - 24px) (65px - 24px);
      }
      grid-column: span 3;

      a {
        text-decoration: none;
      }
    }
  }
}

.block--employee-overview {
  grid-column: 2 / span 12;
  .column {
    grid-column: 2 / -2;

    & + .column {
      margin-top: 80px;
    }
  }

  .employees {
    margin: 80px 0 0;

    @include tablet {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: $grid-gap;
      grid-row-gap: 40px;
    }
  }
}
.block__webinar-overview {
  .block__list {
    display: flex;
    flex-direction: column;
    .overview-list-item {
      grid-column: 1/-1;
      margin: 0 0 40px;
    }
    .event {
      display: grid;
      grid-template-areas:
        "a b b"
        "a c c";
      gap: 0 25px;
      @include tablet {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 40px;
      }
      @include desktop {
        gap: 80px;
      }
      .pre-title {
        grid-area: a;
        display: inline-block;
        width: 50px;
      }
      .event__title {
        display: inline-block;
        font-family: $font-primary;
        font-size: 18px;
        color: $secondary-color;
        margin-bottom: 10px;
        span {
          margin-right: 4px;
          color: #7e9492;
        }
        @include tablet {
          font-size: 21px;
        }
      }
      .event--content {
        flex: 1;
        grid-area: b;
        margin-bottom: 10px;
        @include tablet {
          margin-bottom: 0;
        }
      }
      .event__time {
        margin-top: 10px;
        font-size: 15px;
        color: #7e9492;
        font-weight: bold;
        font-family: $font-secondary;
        i {
          margin-right: 8px;
        }
      }
      .event__link {
        grid-area: c;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        @include tablet {
          justify-content: flex-end;
        }
        a {
          white-space: nowrap;
        }
      }
    }
  }
  .webinars {
    margin: 50px 1.5rem;
    @include tablet {
      margin: 100px 0;
    }
    .container,
    .np-container {
      padding: 0;
    }
    &--is-past {
      .pre-title {
        color: $forest-green-dark;
        &::before {
          background-color: $forest-green-dark;
        }
      }
      .event {
        .event__title {
          color: $forest-green-dark;
          span {
            color: $forest-green-dark;
          }
        }
        .event__summery {
          color: $forest-green-dark;
        }
      }
    }

    &__title {
      grid-column: 2 / span 12;
    }
    &__list {
      grid-column: 2 / span 12;
      padding: 3rem 0 0;
      .block__list {
        display: block;
      }
      .overview-list-item {
        &:not(:first-of-type),
        &:not(:last-of-type) {
          margin: 50px 0;
          @include tablet {
            margin: 100px 0;
          }
        }
        &:first-of-type {
          margin-top: 3rem;
        }
        &:last-of-type {
          margin-bottom: 3rem;
        }
      }
    }
  }
}
