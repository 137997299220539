.nav-fullscreen {
  display: none;
  position: fixed;
  z-index: 10000000000; // use this to hide the chat button
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: $background-dark;
  color: #fff;

  a {
    color: #fff;
    margin: 8px 0 0;
    font-size: 14px;
    font-family: $font-secondary;

    @include tablet {
      font-size: 15px;
    }
  }

  h4 {
    margin-bottom: 16px;
    font-size: 14px;
  }

  @include mobile-only {
    .lines {
      display: none;
    }
  }
}

.nav-fullscreen--open {
  display: block;
}

.nav-fullscreen__container {
  display: flex;
  flex-direction: column;

  margin: 140px auto 50px;
  max-width: 1600px;
  height: calc(100% - 110px);

  @include mobile-only {
    overflow-y: scroll;
  }
  @include tablet {
    justify-content: space-between;
    padding-bottom: 100px;
  }
}

.nav-fullscreen__columns {
  display: flex;
  flex-direction: column;

  padding: 0 2.5rem;

  @include tablet {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.nav-search {
  padding: 0 2.5rem;
  margin-top: 25px;

  &.mobile {
    display: none;
    @include until-tablet {
      display: block;
    }
  }
  &.desktop {
    display: block;
    max-width: 200px;
    @include until-tablet {
      display: none;
    }
  }
  .nav-search__inner {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .input__icon {
    font-size: 14px;
    color: white;
  }
  .input__field {
    border: none;
    border-radius: 0;
    color: white;
    background: #5c5f66;
    -webkit-appearance: none;
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
    &::placeholder {
      color: white;
    }
  }
}

.full-nav-button {
  display: inline-flex;
  margin: 25px auto 0;
  font-size: 20px;
  @include tablet {
    display: none;
  }
  li > * {
    font-size: 18px;
  }
  a {
    color: white;
    @include on-event {
      color: $primary-color;
    }
  }
}

.nav-fullscreen__top {
  @include mobile-only {
    margin-top: 32px;
  }
}

.nav-fullscreen__bottom-wrapper {
  padding: 0 2.5rem;

  @include tablet {
    display: flex;
    justify-content: space-between;

    padding: 0 3.5rem;
  }
}

.nav-fullscreen__bottom {
  display: flex;
  flex-direction: column;
  flex: 0 1 0;

  @include mobile-only {
    margin-top: 32px;
  }
}

.nav-fullscreen__top,
.nav-fullscreen__bottom-primary,
.nav-fullscreen__bottom-secondary {
  a {
    display: inline-block;
    margin: 0 0 12px;
    min-width: 50%;
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    @include on-event {
      text-decoration: underline;
    }

    @include tablet {
      margin: 0 0 0 38px;
      min-width: 150px;
    }
  }
}

.nav-fullscreen__bottom-primary,
.nav-fullscreen__bottom-secondary {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;

  a {
    margin: 0;
    max-width: unset;
    grid-column: span 1;
    @include tablet {
      grid-column: span 3;
    }
  }
}

.full-nav-button--language {
  li a,
  li .active {
    font-size: 15px;
  }
}

.nav-fullscreen__certification {
  grid-column: 2 / 14;
  grid-row: 4 / 5;
  align-self: self-end;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  @include mobile-only {
    opacity: 0.5;
  }

  @include tablet {
    margin-top: 0;
    white-space: nowrap;
  }

  p {
    font-size: 12px;
    text-transform: uppercase;
  }
}

.nav-column {
  display: flex;
  flex-direction: column;

  @include mobile-only {
    display: block;
  }

  @include tablet {
    flex-grow: 1;
  }

  &:last-child {
    margin-bottom: 32px;
  }

  .dots {
    @include dots-divider;
    background-size: 3px 3px;
    height: 9px;
    display: none;

    @include tablet {
      display: block;
    }
  }

  @include tablet {
    padding-top: 40px;
    margin: 0 1rem;
  }

  @include mobile-only {
    .nav-column__title {
      display: flex;
      margin: 0;
      padding: 20px 0;
      border-bottom: 1px solid $line-dark;

      &:after {
        content: "\f077";
        display: inline-block;
        margin: 0 0 0 auto;
        font-family: $font-awesome;
      }
    }

    a {
      height: 32px;
      text-transform: uppercase;
    }
  }

  &--open {
    .nav-column__content {
      display: flex;
      margin-bottom: 0;
    }

    .nav-column__title {
      border-bottom: none;
      @include mobile-only {
        color: $primary-color;
      }
      &:before {
        content: "";
        margin: 0 15px 0 -15px;
        height: 100%;
        width: 1px;
        background: $primary-color;
      }

      &:after {
        transform: rotate(180deg);
      }
    }
  }
}

.nav-column__content {
  display: flex;
  flex-direction: column;

  @include mobile-only {
    display: none;
    background-color: #20232b;
    margin: 0 -40px 32px;
    padding: 24px 40px 16px;
    border-bottom: 1px solid $line-dark;
  }
}

.nav-column--first {
  @include tablet {
    min-width: 190px;

    .nav-column__title,
    .dots {
      display: none;
    }
    h4 {
      font-size: 18px;
    }

    a {
      margin: 0 0 4px;
      font-size: 18px;
      font-family: $font-secondary;
      text-transform: uppercase;

      &:before {
        content: "\f061";
        display: inline-block;
        margin: 0 12px 0 0;
        transform: rotate(45deg);
        font-family: $font-awesome;
      }
    }
  }
}
