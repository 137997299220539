.form-header {
  min-height: 60vh;
  background: transparent;

  @include mobile-only {
    margin-bottom: 70px;
  }

  &:before,
  &:after {
    display: none;
  }

  .container,
  .np-container {
    @include tablet {
      grid-template-rows: 150px 120px 15vh 240px 1fr;
    }

    > *:not(.image-container) {
      position: relative;
      z-index: 2;
    }
  }

  .image-container {
    @include tablet {
      grid-column: 1 / 15;
      grid-row: 1 / 5;
      position: relative;
    }
  }

  .header-submenu {
    grid-column: 2 / 14;
    grid-row: 1 / 2;
    margin: 90px 0 0;
  }

  .breadcrumb {
    margin: 72px 0 32px;
    grid-column: 2 / 14;
    grid-row: 1 / 2;
    color: $text-dark;
    li {
      &.is-active a {
        color: white;
      }
    }
    @include tablet {
      margin: 90px 0 0;
    }
  }

  h1 {
    @include mobile-only {
      margin: 32px 0 0;
    }

    @include tablet {
      grid-column: 3 / 8;
      grid-row: 2 / 3;
      align-self: self-start;
    }
  }

  .header-description {
    margin: 8px 0 0;

    @include tablet {
      grid-column: 8 / 14;
      grid-row: 2 / 3;
      align-self: self-start;
      margin: 0;
    }
  }

  .header-form-fullwidth {
    @include mobile-only {
      margin: 32px 0 -70px;
    }
    @include tablet {
      grid-column: 4 / 12;
      grid-row: 4 / 6;
    }
  }

  form p {
    margin: inherit;
  }
}
