// Required Core Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.glide {
  &--carousel {
    width: calc(100dvw - 32px);
    max-width: 1440px;
  }

  .glide__slide {
    height: auto;
  }
}
