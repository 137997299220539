.block--re-usable-content-block {
  & > .container,
  & > .np-container {
    padding: 0;
    margin: 0;
    display: block;
  }
  .block__content {
    .dev-label {
      top: 2.5em;
      background: $primary-color-alt;
    }
  }
}
