.card-small {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $text-dark;
  border-radius: 3px;

  @include mobile-only {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  &:hover {
    .button--card {
      display: flex;

      &:before {
        display: none;
      }
    }
    .card-small__content {
      background: $white;
    }
    .card-small__content,
    .button--card {
      &:before {
        display: block;
      }
    }
  }

  .card-pre-title {
    margin: 0 0 8px;
    color: $primary-color-alt;
    font-size: 14px;
  }

  .wysiwyg {
    margin-top: 12px;
  }

  .wysiwyg,
  .wysiwyg p {
    font-size: 15px;
  }

  .button--card {
    display: flex;
    justify-content: flex-start;
    margin-top: 2px;
    padding: 0 20px;
    max-width: 100%;
    font-size: 13px;
    text-decoration: underline;
    @include button--primary;
    padding: 0 20px;
    &:after {
      transition: all 0.2s;
    }

    &:hover {
      box-shadow: $soft-shadow;

      &:before {
        display: none;
      }
      &:after {
        transform: rotate(0deg);
      }
    }
  }

  .button--card,
  .card-small__content {
    position: relative;
    &:before {
      content: "";
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      box-shadow: $soft-shadow;
    }
  }
}

.card-small__content {
  padding: 16px;
  color: $text-dark;

  &:hover {
    text-decoration: none;
    .title {
      color: $primary-color;
      text-decoration: underline;
    }
  }
}
