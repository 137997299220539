.block__support-questions {
  .container,
  .np-container {
    @include tablet {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
  .block__questions {
    grid-column: 2 / span 12;
  }
  .title {
    line-height: 36px;
    font-weight: bold;
    font-stretch: condensed;
    letter-spacing: -0.02em;
    color: $text-alt;
    font-size: 28px;
    @include tablet {
      font-size: 34px;
    }
  }
}
