.block--compare {
  position: relative;
  max-width: 100%;

  .container,
  .np-container {
    grid-row-gap: 40px;
  }

  .block__intro {
    grid-row: 1 / 2;
    grid-column: 2 / 14;
  }

  .block__content {
    grid-row: 2 / 3;
    grid-column: 2 / 14;
  }

  .table {
    display: grid;
    position: relative;
    overflow-x: auto;
    margin: 0 #{-$container-padding-mobile};
    border-right: 1px solid rgba($primary-color, 0.1);

    @include tablet {
      padding: 80px 0 0;
      margin: 0 -13px 0 -12px;
    }
  }

  .table__item {
    font-size: 14px;
    background: #fff;
    min-width: 44vw;
    @include tablet {
      min-width: 195px;
    }

    &.row1.col1 {
      background: none;
    }
    &.col1 {
      > * {
        text-align: left !important;
      }
    }

    @include tablet {
      font-size: 15px;
    }

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .icon {
      display: block;
      width: 100%;
      text-align: center;
      color: $primary-color;
      font-size: 22px;
    }
  }

  .table__item:not(.row1) {
    text-align: center;
    > * {
      text-align: center;
    }
    ul,
    ol {
      text-align: left;
    }
  }

  .table__item:not(.table__item--title) {
    border-bottom: 1px solid rgba($primary-color, 0.1);
  }
  .table__item:not(.col1):not(.table-head) {
    padding: 8px 20px;
  }
  .table__item:not(.col1):not(.col2):not(.table-head) {
    border-left: 1px solid rgba($primary-color, 0.1);
  }

  .table__item--title {
    grid-column: 1 / -1;
    background: $background-light;

    .table__leading-column {
      background: $background-light;
    }

    strong {
      color: $primary-color;
      font-family: $font-secondary;
      text-transform: uppercase;
    }
  }

  .table__leading-column {
    position: relative;
    z-index: 1;
    padding: 8px 16px;
    height: 100%;
    width: 100%;
    background: #fff;
    border-right: 1px solid rgba($primary-color, 0.1);

    @include tablet {
      padding-left: $grid-gap;
    }
  }

  @for $i from 1 through 6 {
    .col#{$i} {
      grid-column-start: $i;
      grid-column-end: ($i + 1);
    }
  }

  @for $i from 1 through 20 {
    .row#{$i} {
      grid-row-start: $i;
      grid-row-end: ($i + 1);
    }
  }

  .table-head {
    position: relative;
    margin: 20px 0 0;
    padding: 30px 20px 20px;
    background: #fff;
    box-shadow: $regular-shadow;

    @include tablet {
      margin: -50px 0 0;
    }

    .button--primary {
      padding: 0 10px;
      &:before,
      &:after {
        display: none;
      }
    }
  }

  .table-head__label {
    position: absolute;
    left: auto;
    right: 10px;
    top: 0;
    transform: translateY(-50%);
    padding: 0 12px;
    font-size: 14px;
    font-family: $font-secondary;
    text-transform: uppercase;
    color: $white;
    background: $background-dark;
  }

  .table-head__button,
  .table-head__text {
    margin-top: 8px;
  }

  .table-head__text p {
    font-size: 14px;

    @include tablet {
      font-size: 15px;
    }
  }
}
