// Call to Action
$primary-color: rgb(47, 142, 210); // #2f8ed2
$primary-color-darker: rgb(42, 131, 195); // #2a83c3
$primary-color-alt: rgb(167, 192, 218); // #a7c0da

$secondary-color: rgb(42, 46, 56); // #a7c0da
$secondary-color-darker: rgb(0, 0, 0); // #a7c0da

$white: rgb(255, 255, 255); // #ffffff
$forest-green: rgba(126, 148, 146, 1); //7E9492
$forest-green-light: rgba(177, 197, 204, 0.6); //B1C5CC99 w/ 60% opacity
$forest-green-dark: rgba(126, 148, 146, 1); // #7E9492
$forest-green-alt: rgba(215, 233, 232, 1); // #D7E9E8

$orange: rgba(223, 163, 14, 1); //DFA30E

// Typography
$text-white: $white;
$text-dark: $secondary-color;
$text-primary: $primary-color;
$text-secondary: $secondary-color; // #2a2e38;
$text-alt: $primary-color-alt; // #a7c0da
$text-gray: rgb(200, 201, 204); // #a7c0da

// Background planes
$background-white: rgb(255, 255, 255); // #ffffff
$background-light: rgb(244, 247, 250); // #F4F7FA
$background-blue: rgba(172, 191, 214, 1); //#ACBFD6
$background-dark: $secondary-color;
$background-alt: $secondary-color;

// Lines
$line-color: rgb(215, 233, 232);
$line-dark: #3a3f4b; //3a3f4b

// Buttons
$newsletter: $primary-color;
$form-newsletter: rgb(89, 96, 113); // #596071;

// Table
$background-title: rgb(244, 247, 250); //F4F7FA

// Form
$form-field-border: $primary-color-alt; // #a7c0da
$form-field-background: $background-light; // #F4F7FA
$form-field-placeholder: $primary-color; // #2f8ed2
$form-field-filled: rgb(47, 52, 65); // #2f3441
$form-field-error: rgb(176, 0, 32); // #b00020
