.block--text-block {
  .block__content {
    grid-column: 4 / 12;
    .title {
      margin-bottom: 24px;
    }
    &.theme--dark {
      margin-top: -25px;
      padding: 60px 60px 50px;
      background: $background-dark;
      @include tablet {
        margin-top: -50px;
      }
      .title {
        color: white;
      }
      .wysiwyg {
        > *:not(pre):not(a) {
          color: white;
        }

        blockquote {
          border-left: 4px solid white;
        }
        a:hover,
        a:focus {
          color: white;
        }
        pre {
          background: rgba(255, 255, 255, 0.7);
          color: $text-dark;
        }
      }
    }
  }
  .button--primary {
    margin: 24px 0 0;
  }
}
.block--text-block + .block--text-block .container,
.block--text-block + .block--text-block .np-container {
  padding-top: 0;
}
