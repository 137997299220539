.block--video {
  &.colorscheme-dark {
    background: $background-dark;
  }

  .container,
  .np-container {
    padding: 0;
    & > * {
      grid-column: 2 / 14;
      position: relative;
      // padding-bottom: 56.25%;
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }
    figure {
      padding-top: 56.5%;
    }
  }

  iframe,
  video {
    object-fit: cover;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}
