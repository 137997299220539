// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@use "sass:list";

@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin basic-reset {
  list-style: none;
  background: none;
  text-decoration: none;
  border: none;
  margin: 0;
  padding: 0;
}

@mixin limit-lines($amount) {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $amount;
  -webkit-box-orient: vertical;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin dots-divider {
  display: block;
  margin: 0 0 8px;
  height: 15px;
  width: 100%;

  background-image: radial-gradient(#fff 40%, transparent 60%);
  background-size: 5px 5px;
  background-position: 0 0;
}

@mixin gitp-arrow {
  &:after {
    content: "\f061";
    @include flex-center;
    position: absolute;
    top: 0;
    right: 0;
    font-family: $font-awesome;
    font-size: 20px;
    height: 100%;
    width: 48px;
    transform: rotate(45deg);
  }
}
@mixin dots-background {
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: radial-gradient(rgba(0, 0, 0, 0.5) 30%, rgba(47, 142, 210, 0.1) 40%);
    background-position: 0 0;
    background-size: 3px 3px;
  }
}
@mixin dots-background-light {
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: radial-gradient(rgba(55, 55, 55, 0.5) 20%, rgba(255, 255, 255, 0.1) 10%);
    background-position: 0 0;
    background-size: 3px 3px;
  }
}

/// Make a context based selector a little more friendly
/// @author Colin Dorr
/// @param {Number} $content-start
/// @param {Number} $image-start

@mixin textImage($content-start, $image-start) {
  // Text Left
  @if $content-start < $image-start {
    .block__header,
    .block__content {
      grid-column: list.slash($content-start, $image-start - 1);
    }
    .block__image {
      grid-column: list.slash($image-start, 15);
    }
  }
  // Text Right
  @else {
    .block__image {
      grid-column: list.slash($image-start, $content-start - 1);
    }
    .block__header,
    .block__content {
      grid-column: list.slash($content-start, 14);
    }
  }
}
