// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
button {
  -webkit-appearance: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

.button,
[class^="button--"] {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 48px;
  width: auto;
  max-width: 400px;
  font-family: $font-secondary;
  font-weight: bold;
  transition: all 0.2s;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 14px;
}

//This get's used elsewhere, hence the mixin
@mixin button--primary {
  position: relative;
  overflow: hidden;
  padding-right: 68px;
  background: $primary-color;
  color: $text-white;
  text-decoration: none;

  @include gitp-arrow;

  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 48px;
    background: $primary-color-darker;
  }
}

.button--content-width {
  width: max-content;
}

.button--primary {
  @include button--primary;

  &:after {
    transition: all 0.2s;
  }
}

.button--primary {
  
  &--single-color {
    padding-right: 50px;

    &:before {
      background: $primary-color;
    }

    &:hover {

      &:before {
        background: $primary-color-darker;
      }
    }
  }
  &:hover {
    color: $text-white;
    text-decoration: none;
    background: $primary-color-darker;
    &:after {
      transform: rotate(0deg);
    }
  }

  &--single-color{
    padding-right: 50px;
    &:before{
      background: unset;
    }
    &:hover {
      &:before{
        background: unset;
      }
    }
  } 
}

.button--secondary {
  justify-content: flex-start;
  padding: 0;
  background: transparent;
  color: $text-secondary;
  text-decoration: underline;
}

.button--tertiary {
  background: white;
  width: 100%;
  color: $text-dark;
  display: inline-flex;
  @include desktop {
    width: auto;
  }
  @include on-event {
    background: $primary-color-darker;
    color: $text-white;
    text-decoration: none;
    &.with-arrow {
      &:after {
        transform: rotate(0deg);
      }
    }
  }
  &.with-arrow {
    padding: 0 40px 0 20px;
    position: relative;

    &:after {
      content: "\f061";
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      font-family: "Font Awesome 5 Pro";
      font-size: 20px;
      height: 100%;
      width: 48px;
      transform: rotate(45deg);
      transition: all 0.3s;
    }
  }
}

.header-video-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  gap: 9px;
  left: 50%;
  bottom: 35px;
  transform: translateX(-50%);
  padding: 4px 8px 4px 16px;
  font-family: $font-secondary;
  color: white;
  background: none;
  border: 1px solid white;
  border-radius: 55px;
  transition: all 0.3s;
  z-index: 3;
  min-height: unset;
  height: auto;

  @include desktop {
    left: 25px;
    bottom: 48px;
    transform: none;

    &.open {
      bottom: 90px;
    }
  }

  .icon__container {
    width: 24px;
    height: 24px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.3s;
  }

  .fa-play {
    color: $text-primary;
    display: inline-block;
    position: relative;
    margin: 0;
    font-size: 10px;
    transition: all 0.3s;
    
    &:nth-of-type(1) {
      display: inline;
      z-index: 1;
      margin-right: -0.4em;
    }

    &:nth-of-type(2) {
      margin: 0;
      color: lighten($text-primary, 20%);
      opacity: 0.8;
      z-index: 0;
    }
  }
  @include on-event {
    background: $primary-color;
    border-color: $primary-color;
    .icon__container {
      background: none;
    }
    .fa-play {
      color: white;

      &:nth-of-type(1) {
        margin-left: 8px;
      }
      
      &:nth-of-type(2) {
        opacity: 0.8;
        color: lighten(white, 10%);
      }
    }
  }
}

.button {
  &--disabled,
  &--disabled::before,
  &:disabled,
  &:disabled::before {
    background-color: $forest-green-light !important;
  }
}

//Colorscheme specific
.colorscheme-dark {
  .button--secondary {
    justify-content: center;
    padding: 0 20px;
    background: #3a3f4b;
    color: $text-white;
    text-decoration: none;
  }
}
