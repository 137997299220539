.radio-field-with-instructions {
  position: relative;
  display: flex;
  align-items: center;
  margin: 8px 0 0;
  padding: 8px 40px 8px 12px;
  min-height: 42px;
  width: 100%;

  .radio-field-with-instructions__style {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    border-radius: 3px;
    height: 100%;
    width: 100%;
    background: $background-light;
    border: 2px solid $primary-color-alt;
    transition: all 0.2s;
  }

  label {
    display: flex;
    align-items: center;
    padding: 0 12px;
    min-height: 42px;
  }

  .text {
    margin-left: 4px;
  }

  .open-instructions {
    position: absolute;
    height: 100%;
    width: 42px;
    top: 0;
    right: 0;
    background: none;
  }

  input:checked {
    & ~ .radio-field-with-instructions__style {
      background: $secondary-color;
      border-color: $secondary-color;
    }

    & ~ .open-instructions,
    & ~ span {
      color: #fff;
    }
  }
}
