.block--cards-small {
  .filters {
    margin: 40px 0 0;

    .h5 {
      font-weight: normal;
    }
  }

  &:nth-of-type(2n) {
    background: $background-light;
  }

  .block__content {
    @include mobile-only {
      padding-bottom: 50px;
    }

    @include tablet {
      grid-column: 2 / 8;
    }

    .description {
      font-size: 16px;
    }
  }

  .block__cards {
    @include tablet {
      grid-column: 2 / 14;
      grid-row: 2 / 3;
      margin: 32px 0 0;
    }
  }

  .card-filter {
    background: $primary-color-alt;
    &.card-filter--active {
      background: $primary-color;
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;

    @include mobile-only {
      flex-direction: column;
    }

    &.three-or-less {
      @include tablet {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 25px;
        grid-row-gap: 25px;
      }
    }

    &.four-or-more {
      @include tablet {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 25px;
        grid-row-gap: 25px;
      }
      @include desktop {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }
}
