.training-details-dates {
  padding-bottom: 4rem;

  &__inner {
    grid-column: 2/14;

    width: 100%;

    @include desktop {
      width: 50%;
    }
  }

  &__title {
    margin-bottom: 1rem;

    font-size: 18px;

    @include tablet {
      margin-bottom: 1.5rem;

      font-size: 34px;
    }
  }

  &__select {
    margin: 0.5rem 0 1rem;
    height: 50px;
    max-width: 525px;

    background-color: $background-light;

    font-family: $font-secondary;
    font-size: 14px;
    text-transform: uppercase;

    -webkit-appearance: none;

    @include desktop {
      display: none;
    }
  }

  &__select-container {
    position: relative;
    max-width: 400px;

    @include desktop {
      display: none;
    }

    .fa-chevron-down {
      position: absolute;
      bottom: 24px;
      right: 1rem;
      height: 18px;
      width: auto;
      border: none;
      text-align: center;
      line-height: 50px;
      pointer-events: none;
      transform: translateY(50%);
    }
  }

  &__table {
    width: 100%;
    max-width: 525px;
    overflow-x: hidden;
  }

  table,
  th,
  td {
    padding: 0;

    border: 0 solid;
    background-color: transparent;
  }

  th {
    min-width: 80px;
    padding-top: 1rem;

    font-family: $font-primary;
    font-size: 14px;
    color: $primary-color-alt;
    text-transform: uppercase;

    @include desktop {
      font-size: 21px;
      min-width: 160px;
    }
  }

  td {
    padding-bottom: 1.5rem;

    border-bottom: 1px solid $primary-color-alt;

    @include desktop {
      border-bottom: none;
    }
  }
}
