.menu-open {
  .banner_outer {
    z-index: 1 !important;
  }
}

.banner_outer {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  transition: all 0.3;
  background: $primary-color;
  animation: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both;
  .banner_inner {
    padding: 0 20px;
    background: $primary-color;
    @include tablet {
      display: grid;
      grid-template-columns: 65px 112px auto 0 0 0 0 65px;
    }
  }
  .banner_content {
    grid-column: 2/-2;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
    gap: 25px;
    padding: 8px 0;
    color: white;
    font-size: 14px;
    a {
      color: inherit;
      text-decoration: underline;
    }
    .close_btn {
      background: none;
      color: inherit;
      font-size: 20px;
    }
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
