.training-details-related-trainings {
  background-color: $background-light;

  &__inner {
    padding: 4rem 0;

    grid-column: 2/14;
  }

  &__title {
    font-size: 2rem;

    @include desktop {
      margin-bottom: 2rem;

      font-size: 32px;
      line-height: 40px;
    }
  }

  &__list {
    margin-top: 1rem;

    list-style-type: none;

    @include desktop {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      margin-left: -1rem;
      margin-right: -1rem;
    }
  }

  &__list-item {
    margin-bottom: 1rem;
  }

  &__card {
    width: 100%;

    display: inline-block;

    &:hover,
    &:focus {
      color: $text-dark;
      text-decoration: none;
      .button--primary {
        &::after {
          transform: rotate(0deg);
        }
      }
    }
  }

  &__card-inner {
    padding: 2rem;

    color: $text-dark;

    background-color: $white;

    @include desktop {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      padding: 1rem;
      width: 250px;
      min-height: 410px;

      background-color: initial;

      transition: box-shadow 0.5s, background-color 0.2s, transform 0.4s;

      &:hover,
      &:focus {
        background-color: $white;
        box-shadow: 0px 2px 40px 5px rgba(0, 0, 0, 0.1);

        transform: translateY(-6px);
      }
    }
  }

  &__card-details {
    @include desktop {
      padding-top: 60px;
    }
  }

  &__reviews-title {
    font-family: $font-primary;
    font-size: 1.25rem;
    @include desktop {
      font-size: 22px;
      line-height: 22px;
    }
  }

  &__rating {
    display: flex;

    margin-bottom: 1rem;

    color: $orange;
  }

  &__reviews-counter {
    margin-left: 0.5rem;
  }

  &__type {
    display: inline-block;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;

    color: $white;

    font-family: $font-secondary;
    font-size: 14px;

    background-color: $forest-green;

    @include desktop {
      position: absolute;
    }
  }

  &__description {
    margin: 1rem 0;
    max-width: 525px;

    display: -webkit-box;
    width: 100%;

    font-size: 13px;
    line-height: 19px;

    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;

    overflow: hidden;
    text-overflow: ellipsis;
  }
}
